import React, {useEffect, useState, useContext} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {Button, Container, Form, FormGroup, Input, Label} from 'reactstrap';
import AppNavbar from '../components/AppNavbar';
import User from '../context/ContextApi';

const LoanIssuanceEdit = () => {
  const { data } = useContext(User);
  const navigate = useNavigate();

      const initialFormState = {
        idNumber: '',
        issuanceDate: '',
        loanAmount: '',
        loanInterestAmount: '',
        loanInterestPaymentStatus: '',
        loanBalance: '',
        loanDisbursedAmount: '',
        loaneeImage: '',
        name: '',
        phoneNumber: '',
        repaymentDate: '',
        residence: '',
        company: {id: '', name: '', status:''},
        lendingStation: {
            id: '', 
            name: '', 
            company: {id: '', name: '', status:''}, 
            status:''},
        stationCoordinator: {
            id: '',
            idNumber: '',
            name: '',
            lendingStation: {
                id: '',
                name: '',
                company: {id: '', name: '', status:''},
                status:''
            },
            company: {id: '', name: '', status:''},
            status: '',
            registrationDate: '',
            deregistrationDate: ''
        }
      };

      const [loanissuance, setLoanissuance] = useState(initialFormState);
      const {id} = useParams();

      useEffect(() => {
        if(id !== 'new'){
            fetch(`/api/v1/loanissuances/${id}`)
            .then(response => response.json())
            .then(data => setLoanissuance(data));
        }
      }, [id, setLoanissuance]);

      useEffect(() => {
        if (!data.accessToken) {
          navigate('/'); // Redirect to login if not authenticated
        }
      }, [data.accessToken, navigate]);

      const handleChange = (event) => {
        const { name, value } = event.target;
        const updatedLoanIssuance = { ...loanissuance };
      
        // Handle nested company & lendingStation objects within stationCoordinator
        if (name.startsWith('stationCoordinator.')) {
            const outerNestedName = name.split('.')[1];
            // Handle nested lendingStation within stationCoordinator object
            if (outerNestedName.startsWith('stationCoordinator.lendingStation.')) {
                const nestedName = outerNestedName.split('.')[1];
                // Handle nested company within lendingStation object
                if (nestedName.startsWith('stationCoordinator.lendingStation.company.')) {
                    const nestednestedName = nestedName.split('.')[1];
                    updatedLoanIssuance.lendingStation.company[nestednestedName] = value;
                } else {
                    updatedLoanIssuance.lendingStation[nestedName] = value;
                }            
            } 
            // Handle nested company within stationCoordinator object
            else if (outerNestedName.startsWith('stationCoordinator.company.')) {
                const nestednestedName = outerNestedName.split('.')[1];
                updatedLoanIssuance.lendingStation.company[nestednestedName] = value;
            } else {
                updatedLoanIssuance.lendingStation[outerNestedName] = value;
            }            
        } else if (name.startsWith('lendingStation.')) {
            const nestedName = name.split('.')[1];
            // Handle nested company within lendingStation object
            if (nestedName.startsWith('lendingStation.company.')) {
                const nestednestedName = nestedName.split('.')[1];
                updatedLoanIssuance.lendingStation.company[nestednestedName] = value;
            } else {
                updatedLoanIssuance.lendingStation[nestedName] = value;
            }            
        } else if (name.startsWith('company.')) {
            const nestedName = name.split('.')[1];
            updatedLoanIssuance.company[nestedName] = value;
        } else {
            updatedLoanIssuance[name] = value;
        }

        if(id === 'new'){
          const currentDate = new Date();
          const offset = currentDate.getTimezoneOffset() * 60000; // Get the offset in milliseconds

          // Adjust the current date and time by adding the offset for GMT+3
          const adjustedDate = new Date(currentDate.getTime() - offset);

          const formattedDate = adjustedDate.toISOString();
          updatedLoanIssuance.issuanceDate = formattedDate;
        }

        setLoanissuance(updatedLoanIssuance);
      };
      

      const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await fetch(`/api/v1/loanissuances${loanissuance.id ? `/${loanissuance.id}` : ''}`, {
            method: (loanissuance.id) ? 'PUT' : 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(loanissuance)
            });
            
            setLoanissuance(initialFormState);
            navigate('/loanissuances');

            if (response.ok) {
            // Handle the successful response, e.g., show a success message
            console.log('Loan Issuance created successfully!');
            } else {
            // Handle the error response, e.g., show an error message
            console.error('Error creating Loan Issuance else block:', response.statusText);
            }
        } catch (error) {
            // Handle any network or other errors
            console.error('Error creating Loan Issuance catch block:', error);
        }
      }

      const title = <h2>{loanissuance.id ? 'Edit Loan Issuance' : 'Add Loan Issuance'}</h2>;
    
      return (<div>
          <AppNavbar/>
          <Container>
            {title}
            <Form onSubmit={handleSubmit}>
            <FormGroup>
                <Label for="idNumber">ID Number</Label>
                <Input type="text" name="idNumber" id="idNumber" value={loanissuance.idNumber || ''}
                       onChange={handleChange} autoComplete="idNumber"/>
              </FormGroup>
              <FormGroup>
                <Label for="issuanceDate">Issuance Date</Label>
                <Input type="text" name="issuanceDate" id="issuanceDate" value={loanissuance.issuanceDate || ''}
                       onChange={handleChange} autoComplete="date"/>
              </FormGroup>
              <FormGroup>
                <Label for="loanAmount">Loan Amount</Label>
                <Input type="text" name="loanAmount" id="loanAmount" value={loanissuance.loanAmount || ''}
                       onChange={handleChange} autoComplete="amount"/>
              </FormGroup>
              <FormGroup>
                <Label for="loanInterestAmount">Loan Interest Amount</Label>
                <Input type="text" name="loanInterestAmount" id="loanInterestAmount" value={loanissuance.loanInterestAmount || ''}
                       onChange={handleChange} autoComplete="amount"/>
              </FormGroup>
              <FormGroup>
                <Label for="loanInterestPaymentStatus">Loan Interest Payment Status</Label>
                <Input type="text" name="loanInterestPaymentStatus" id="loanInterestPaymentStatus" value={loanissuance.loanInterestPaymentStatus || ''}
                       onChange={handleChange} autoComplete="status"/>
              </FormGroup>
              <FormGroup>
                <Label for="loanBalance">Loan Balance</Label>
                <Input type="text" name="loanBalance" id="loanBalance" value={loanissuance.loanBalance || ''}
                       onChange={handleChange} autoComplete="amount"/>
              </FormGroup>
              <FormGroup>
                <Label for="loanDisbursedAmount">Loan Disbursed Amount</Label>
                <Input type="text" name="loanDisbursedAmount" id="loanDisbursedAmount" value={loanissuance.loanDisbursedAmount || ''}
                       onChange={handleChange} autoComplete="amount"/>
              </FormGroup>
              <FormGroup>
                <Label for="loaneeImage">Loanee Image</Label>
                <Input type="text" name="loaneeImage" id="loaneeImage" value={loanissuance.loaneeImage || ''}
                       onChange={handleChange} autoComplete="image"/>
              </FormGroup>
              <FormGroup>
                <Label for="name">Name</Label>
                <Input type="text" name="name" id="name" value={loanissuance.name || ''}
                       onChange={handleChange} autoComplete="name"/>
              </FormGroup>
              <FormGroup>
                <Label for="phoneNumber">Phone Number</Label>
                <Input type="text" name="phoneNumber" id="phoneNumber" value={loanissuance.phoneNumber || ''}
                       onChange={handleChange} autoComplete="contact-level1"/>
              </FormGroup>
              <FormGroup>
                <Label for="repaymentDate">Repayment Date</Label>
                <Input type="text" name="repaymentDate" id="repaymentDate" value={loanissuance.repaymentDate || ''}
                       onChange={handleChange} autoComplete="date"/>
              </FormGroup>
              <FormGroup>
                <Label for="residence">Residence</Label>
                <Input type="text" name="residence" id="residence" value={loanissuance.residence || ''}
                       onChange={handleChange} autoComplete="contact-level1"/>
              </FormGroup>
              <FormGroup>
                <Label for="companyid">Company Id</Label>
                <Input
                  type="text"
                  name="company.id" // Update the name attribute to "company.id"
                  id="companyid"
                  value={loanissuance.company?.id || ''} // Ensure that the input field is initialized with the id value of the company object, if it exists.
                  onChange={handleChange}
                  autoComplete="contact-level1"
                />
              </FormGroup>
              <FormGroup>
                <Label for="lendingstationid">Lending Station Id</Label>
                <Input
                  type="text"
                  name="lendingStation.id" // Update the name attribute to "lendingStation.id"
                  id="lendingStationid"
                  value={loanissuance.lendingStation?.id || ''} // Ensure that the input field is initialized with the id value of the lendingStation object, if it exists.
                  onChange={handleChange}
                  autoComplete="contact-level1"
                />
              </FormGroup>
              <FormGroup>
                <Label for="stationcoordinatorid">Station Coordinator Id</Label>
                <Input
                  type="text"
                  name="stationCoordinator.id" // Update the name attribute to "stationCoordinator.id"
                  id="stationCoordinatorid"
                  value={loanissuance.stationCoordinator?.id || ''} // Ensure that the input field is initialized with the id value of the stationCoordinator object, if it exists.
                  onChange={handleChange}
                  autoComplete="contact-level1"
                />
              </FormGroup>              

              <FormGroup>
                <Button color="primary" type="submit">Save</Button>{' '}
                <Button color="secondary" tag={Link} to="/loanissuances">Cancel</Button>
              </FormGroup>
            </Form>
          </Container>
        </div>
      )
};

export default LoanIssuanceEdit;