import React, {useEffect, useState, useContext} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {Button, Container, Form, FormGroup, Input, Label} from 'reactstrap';
import AppNavbar from '../components/AppNavbar';
import User from '../context/ContextApi';

const CompanyEdit = () => {
  const { data } = useContext(User);
  const navigate = useNavigate();

      const initialFormState = {
        name: '',
        status: ''
      };

      const [nameError, setNameError] = useState('');
      const [statusError, setStatusError] = useState('');

      const [company, setCompany] = useState(initialFormState);
      const {id} = useParams();

      useEffect(() => {
        if(id !== 'new'){
            fetch(`/api/v1/companies/${id}`)
            .then(response => response.json())
            .then(data => setCompany(data));
        }
      }, [id, setCompany]);

      useEffect(() => {
        if (!data.accessToken) {
          navigate('/'); // Redirect to login if not authenticated
        }
      }, [data.accessToken, navigate]);

      const handleChange = (event) => {
        const {name, value} = event.target

        setCompany({ ...company, [name]: value})
      }

      const handleSubmit = async (event) => {
        event.preventDefault();

        // Reset error messages
        setNameError('');
        setStatusError('');

        // Validate name
        if (!company.name.trim()) {
          setNameError('Name cannot be empty');
          return;
        }

        // Validate status
        if (!company.status.trim()) {
          setStatusError('Status cannot be empty');
          return;
        }

        await fetch(`/api/v1/companies${company.id ? `/${company.id}` : ''}`, {
            method: (company.id) ? 'PUT' : 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(company)
        });
        setCompany(initialFormState);
        navigate('/companies');
      }

      const title = <h2>{company.id ? 'Edit Company' : 'Add Company'}</h2>;
    
      return (<div>
          <AppNavbar/>
          <Container>
            {title}
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label for="name">Name</Label>
                <Input type="text" name="name" id="name" value={company.name || ''}
                       onChange={handleChange} autoComplete="name"/>
                <div className="text-danger">{nameError}</div>
              </FormGroup>
              <FormGroup>
                <Label for="status">Status</Label>
                <Input type="text" name="status" id="status" value={company.status || ''}
                       onChange={handleChange} autoComplete="contact-level1"/>
                <div className="text-danger">{statusError}</div>
              </FormGroup>
              <FormGroup>
                <Button color="primary" type="submit">Save</Button>{' '}
                <Button color="secondary" tag={Link} to="/companies">Cancel</Button>
              </FormGroup>
            </Form>
          </Container>
        </div>
      )
};

export default CompanyEdit;