import React, {useEffect, useState, useContext} from 'react';
import {Button, ButtonGroup, Container, Table, Pagination, PaginationItem, PaginationLink} from 'reactstrap';
import AppNavbar from '../components/AppNavbar';
import {Link, useNavigate } from 'react-router-dom';
import User from '../context/ContextApi';

const InterestSettingList = () => {
  const { data } = useContext(User);
  const navigate = useNavigate();

  const [interestSettings, setInterestSettings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [interestSettingsPerPage] = useState(6);

  useEffect(() => {
    setLoading(true);

    fetch('api/v1/interestsettings')
    .then(response => response.json())
    .then(data => {
      setInterestSettings(data);
      setLoading(false);
    })
  }, []);

  const remove = async (id) => {
    await fetch(`api/v1/interestsettings/${id}`, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(() => {
      let updatedInterestSettings = [...interestSettings].filter(i => i.id !== id);
      setInterestSettings(updatedInterestSettings);
    })
  }

  useEffect(() => {
    if (!data.accessToken) {
      navigate('/'); // Redirect to login if not authenticated
    }
  }, [data.accessToken, navigate]);

  if(loading){
    return <p>Loading...</p>;
  }

  // Calculate indexes of the interestSettings to be displayed on the current page
  const indexOfLastInterestSetting = currentPage * interestSettingsPerPage;
  const indexOfFirstInterestSetting = indexOfLastInterestSetting - interestSettingsPerPage;
  const currentInterestSettings = interestSettings.slice(indexOfFirstInterestSetting, indexOfLastInterestSetting);

  const interestSettingList = currentInterestSettings.map(interestSetting => {
    return <tr key={interestSetting.id}>
        <td style={{whiteSpace: 'nowrap'}}>{interestSetting.id}</td>
        <td style={{whiteSpace: 'nowrap'}}>{interestSetting.interestRate}</td>
        <td style={{whiteSpace: 'nowrap'}}>{interestSetting.interestTerm}</td>
        <td style={{whiteSpace: 'nowrap'}}>{interestSetting.company.id}</td>
        <td>
            <ButtonGroup>
                <Button size="sm" color="primary" tag={Link} to={"/interestSettings/"+interestSetting.id}>Edit</Button>
                <Button size="sm" color="danger" onClick={() => remove(interestSetting.id)}>Delete</Button>
            </ButtonGroup>
        </td>
    </tr>
  });

  // Change page
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Calculate total number of pages
  const totalPages = Math.ceil(interestSettings.length / interestSettingsPerPage);

  return (
    <div>
        <AppNavbar/>
        <Container fluid>
            <div className="float-end">
                <Button color="success" tag={Link} to="/interestSettings/new">Add Interest Setting</Button>
            </div>
            <h3>Interest Settings</h3>
            <Table className="mt-4" responsive>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Interest Rate</th>
                        <th width="40%">Interest Term</th>
                        <th width="10%">Company Id</th>
                        <th width="10%">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {interestSettingList}
                </tbody>
            </Table>
            <Pagination>
              {Array.from({ length: totalPages }, (_, i) => (
                <PaginationItem key={i} active={i + 1 === currentPage}>
                  <PaginationLink onClick={() => handlePageChange(i + 1)}>{i + 1}</PaginationLink>
                </PaginationItem>
              ))}
            </Pagination>
        </Container>
    </div>
  );
}

export default InterestSettingList;
