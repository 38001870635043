import React, {useEffect, useState, useContext} from 'react';
import {Button, ButtonGroup, Container, Table, Pagination, PaginationItem, PaginationLink} from 'reactstrap';
import AppNavbar from '../components/AppNavbar';
import {Link, useNavigate } from 'react-router-dom';
import User from '../context/ContextApi';

const CompanyList = () => {
  const { data } = useContext(User);
  const navigate = useNavigate();

  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [companiesPerPage] = useState(6);

  useEffect(() => {
    setLoading(true);

    fetch('api/v1/companies')
    .then(response => response.json())
    .then(data => {
      setCompanies(data);
      setLoading(false);
    })
  }, []);

  const remove = async (id) => {
    await fetch(`api/v1/companies/${id}`, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(() => {
      let updatedCompanies = [...companies].filter(i => i.id !== id);
      setCompanies(updatedCompanies);
    })
  }

  useEffect(() => {
    if (!data.accessToken) {
      navigate('/'); // Redirect to login if not authenticated
    }
  }, [data.accessToken, navigate]);

  if(loading){
    return <p>Loading...</p>;
  }

  // Calculate indexes of the companies to be displayed on the current page
  const indexOfLastCompany = currentPage * companiesPerPage;
  const indexOfFirstCompany = indexOfLastCompany - companiesPerPage;
  const currentCompanies = companies.slice(indexOfFirstCompany, indexOfLastCompany);

  const companyList = currentCompanies.map(company => {
    return <tr key={company.id}>
        <td style={{whiteSpace: 'nowrap'}}>{company.id}</td>
        <td style={{whiteSpace: 'nowrap'}}>{company.name}</td>
        <td style={{whiteSpace: 'nowrap'}}>{company.status}</td>
        <td>
            <ButtonGroup>
                <Button size="sm" color="primary" tag={Link} to={"/companies/"+company.id}>Edit</Button>
                <Button size="sm" color="danger" onClick={() => remove(company.id)}>Delete</Button>
            </ButtonGroup>
        </td>
    </tr>
  });

  // Change page
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Calculate total number of pages
  const totalPages = Math.ceil(companies.length / companiesPerPage);

  return (
    <div>
        <AppNavbar/>
        <Container fluid>
            <div className="float-end">
                <Button color="success" tag={Link} to="/companies/new">Add Company</Button>
            </div>
            <h3>Companies</h3>
            <Table className="mt-4" responsive>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Name</th>
                        <th width="10%">Status</th>
                        <th width="10%">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {companyList}
                </tbody>
            </Table>
            <Pagination>
              {Array.from({ length: totalPages }, (_, i) => (
                <PaginationItem key={i} active={i + 1 === currentPage}>
                  <PaginationLink onClick={() => handlePageChange(i + 1)}>{i + 1}</PaginationLink>
                </PaginationItem>
              ))}
            </Pagination>
        </Container>
    </div>
  );
}

export default CompanyList;
