import React, {useEffect, useState, useRef, useContext} from 'react';
import {Button, ButtonGroup, Container, Table, Pagination, PaginationItem, PaginationLink} from 'reactstrap';
import AppNavbar from '../components/AppNavbar';
import {Link, useNavigate } from 'react-router-dom';
import User from '../context/ContextApi';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';

const LendingStationList = () => {
  const { data } = useContext(User);
  const navigate = useNavigate();

  const defaultNumber = -1;
  const emptyString = '';
  const firstPage = 1;

  const [lendingStations, setLendingStations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(firstPage);
  const [pageSize] = useState(5);
  const [totalCount, setTotalCount] = useState(0);
  const prevPage = useRef(firstPage);
  // New state variables for the search terms
  const [idFilter, setIdFilter] = useState(defaultNumber);
  const [nameFilter, setNameFilter] = useState(emptyString);
  const [statusFilter, setStatusFilter] = useState(emptyString);
  const [companyIdFilter, setCompanyIdFilter] = useState(defaultNumber);

  // State variable to store the timer reference
  const searchTimer = useRef(null);

  const debouncedSearchTimeoutInMs = 1000;

  useEffect(() => {
    // Clear the previous timer before setting a new one
    if (searchTimer.current) {
      clearTimeout(searchTimer.current);
    }

    // Set a new timer to call the fetchData function after a delay
    searchTimer.current = setTimeout(() => {
    setLoading(true);

    const pageNo = currentPage  - 1;

    const idPlacer = idFilter === defaultNumber ? emptyString : `&id=${idFilter}`;
    const namePlacer = nameFilter === emptyString ? emptyString : `&name=${nameFilter}`;
    const statusPlacer = statusFilter === emptyString ? emptyString : `&status=${statusFilter}`;
    const companyIdPlacer = companyIdFilter === defaultNumber ? emptyString : `&companyId=${companyIdFilter}`;

    const url = `api/v1/lendingstations?pageNo=${pageNo}&pageSize=${pageSize}${idPlacer}${namePlacer}${statusPlacer}${companyIdPlacer}`;

    // Clear the lendingStations state if navigating to a non-sequential page
    if (currentPage !== prevPage.current) {
      setLendingStations([]);
    }

    fetch(url)
      .then(response => response.json())
      .then(data => {
        setLendingStations(prevLendingStations => (currentPage === firstPage ? data.lendingStations : [...prevLendingStations, ...data.lendingStations]));
        setTotalCount(data.totalCount);
        setLoading(false);
      });

    prevPage.current = currentPage; // Update the previous page
    }, debouncedSearchTimeoutInMs); // Adjust the time (in milliseconds) based on your preference

    // Clear the timer when the component unmounts or when the search term changes
    return () => clearTimeout(searchTimer.current);
  }, [defaultNumber, currentPage, pageSize, idFilter, nameFilter, statusFilter, companyIdFilter]);

  useEffect(() => {
    if (!data.accessToken) {
      navigate('/'); // Redirect to login if not authenticated
    }
  }, [data.accessToken, navigate]);

  // Handle the input changes and set the search terms
  const handleIdChange = (e) => {
    setIdFilter((!e.target.value || e.target.value === '-') ? defaultNumber : e.target.value);
    setCurrentPage(firstPage);
  };

  const handleNameChange = (e) => {
    setNameFilter(e.target.value);
    setCurrentPage(firstPage);
  };

  const handleStatusChange = (e) => {
    setStatusFilter(e.target.value);
    setCurrentPage(firstPage);
  };

  const handleCompanyIdChange = (e) => {
    setCompanyIdFilter((!e.target.value || e.target.value === '-') ? defaultNumber : e.target.value);
    setCurrentPage(firstPage);
  };

  const remove = async (id) => {
    await fetch(`api/v1/lendingstations/${id}`, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(() => {
      let updatedLendingStations = [...lendingStations].filter(i => i.id !== id);
      setLendingStations(updatedLendingStations);
    })
  }

  if(loading){
    return <p>Loading...</p>;
  }

  // Calculate indexes of the lendingStations to be displayed on the current page
  const indexOfLastLendingStation = Math.min(currentPage * pageSize, lendingStations.length);
  const indexOfFirstLendingStation = Math.max(indexOfLastLendingStation - pageSize, 0);
  const currentLendingStations = lendingStations.slice(indexOfFirstLendingStation, indexOfLastLendingStation);

  const lendingStationList = currentLendingStations.map(lendingStation => {
    return <tr key={lendingStation.id}>
        <td style={{whiteSpace: 'nowrap'}}>{lendingStation.id}</td>
        <td style={{whiteSpace: 'nowrap'}}>{lendingStation.name}</td>
        <td style={{whiteSpace: 'nowrap'}}>{lendingStation.status}</td>
        <td style={{whiteSpace: 'nowrap'}}>{lendingStation.company.id}</td>
        <td>
            <ButtonGroup>
                <Button size="sm" color="primary" tag={Link} to={"/lendingStations/"+lendingStation.id}>Edit</Button>
                <Button size="sm" color="danger" onClick={() => remove(lendingStation.id)}>Delete</Button>
            </ButtonGroup>
        </td>
    </tr>
  });

  // Change page
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Calculate total number of pages
  const totalPages = Math.ceil(totalCount / pageSize);

  library.add(faAngleDoubleLeft, faAngleDoubleRight);

  const renderPaginationItems = () => {
    const visiblePageCount = 5;
    const ellipsis = <span>...</span>;
  
    if (totalPages <= visiblePageCount) {
      return Array.from({ length: totalPages }, (_, i) => (
        <PaginationItem key={i} active={i + 1 === currentPage}>
          <PaginationLink onClick={() => handlePageChange(i + 1)}>{i + 1}</PaginationLink>
        </PaginationItem>
      ));
    }
  
    const isStartEllipsisNeeded = currentPage > visiblePageCount - Math.floor(visiblePageCount / 2);
    const isEndEllipsisNeeded = currentPage < totalPages - Math.floor(visiblePageCount / 2);
  
    const paginationItems = [];
    let startPage = firstPage;
    let endPage = totalPages;
  
    if (isStartEllipsisNeeded) {
      startPage = currentPage - Math.floor(visiblePageCount / 2);
      paginationItems.push(
        <PaginationItem key="start-ellipsis" disabled>
          {ellipsis}
        </PaginationItem>
      );
    }
  
    if (isEndEllipsisNeeded) {
      endPage = currentPage + Math.floor(visiblePageCount / 2);
    }
  
    for (let i = startPage; i <= endPage; i++) {
      paginationItems.push(
        <PaginationItem key={i} active={i === currentPage}>
          <PaginationLink onClick={() => handlePageChange(i)}>{i}</PaginationLink>
        </PaginationItem>
      );
    }
  
    if (isEndEllipsisNeeded) {
      paginationItems.push(
        <PaginationItem key="end-ellipsis" disabled>
          {ellipsis}
        </PaginationItem>
      );
    }
  
    return paginationItems;
  };

  return (
    <div>
        <AppNavbar/>
        <Container fluid>
            <div className="float-end">
                <Button color="success" tag={Link} to="/lendingStations/new">Add Lending Station</Button>
            </div>
            <h3>Lending Stations</h3>
            <Table className="mt-4" responsive>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Name</th>
                        <th width="40%">Status</th>
                        <th width="10%">Company Id</th>
                        <th width="10%">Actions</th>
                    </tr>
                    <tr>
                        <td>
                          <input
                          type="text"
                          id="id"
                          value={idFilter}
                          onChange={handleIdChange}
                          style={{ width: '100px' }} />
                        </td>
                        <td>
                          <input
                          type="text"
                          id="name" 
                          value={nameFilter}
                          onChange={handleNameChange}
                          style={{ width: '200px' }} />
                        </td>
                        <td>
                          <input
                          type="text"
                          id="status" 
                          value={statusFilter}
                          onChange={handleStatusChange}
                          style={{ width: '70px' }} />
                        </td>
                        <td>
                          <input
                          type="text"
                          id="companyId"
                          value={companyIdFilter}
                          onChange={handleCompanyIdChange}
                          style={{ width: '50px' }} />
                        </td>
                        <td></td>
                    </tr>
                </thead>
                <tbody>
                    {lendingStationList}
                </tbody>
            </Table>
            {totalPages > 1 && (
            <Pagination>
              <PaginationItem disabled={currentPage === firstPage}>
                <PaginationLink onClick={() => handlePageChange(firstPage)}>
                  <FontAwesomeIcon icon="angle-double-left" />
                </PaginationLink>
              </PaginationItem>
              <PaginationItem disabled={currentPage === firstPage}>
                <PaginationLink onClick={() => handlePageChange(currentPage - 1)}>
                  Prev
                </PaginationLink>
              </PaginationItem>
              {renderPaginationItems()}
              <PaginationItem disabled={currentPage === totalPages}>
                <PaginationLink onClick={() => handlePageChange(currentPage + 1)}>
                  Next
                </PaginationLink>
              </PaginationItem>
              <PaginationItem disabled={currentPage === totalPages}>
                <PaginationLink onClick={() => handlePageChange(totalPages)}>
                  <FontAwesomeIcon icon="angle-double-right" />
                </PaginationLink>
              </PaginationItem>
            </Pagination>
            )}
        </Container>
    </div>
  );
}

export default LendingStationList;
