import './Login.css';
import React, { useContext, useEffect, useState,  } from 'react';
import { useNavigate } from 'react-router-dom';
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import {auth} from "../firebase/firebaseConfig";
import User from '../context/ContextApi';
import GoogleButton from 'react-google-button';
import CryptoJS from 'crypto-js';

const ENCRYPTION_KEY_BASE64 = process.env.REACT_APP_DECRYPTION_KEY;

const Login = ({ setIsLoggedIn }) => {

  const [allowedEmails, setAllowedEmails] = useState([]);
  const {setData} = useContext(User);
  const navigate = useNavigate();
  const logo = process.env.PUBLIC_URL + '/mwimbwaniriuwa_art.png';

  //--------------------encryption starts----------------------------------------------//
  useEffect(() => {
    // Make the API request to fetch the encrypted allowed emails
    fetch('api/v1/allowedemails')
      .then(response => response.text())
      .then(encryptedData => {
        const decryptedData = decryptData(encryptedData);
        
        // Split the decrypted string to get individual email addresses
        const decryptedEmails = decryptedData.split(',');
        setAllowedEmails(decryptedEmails);
      })
      .catch(error => {
        console.error('Error fetching allowed emails:', error);
      });
  }, []);

  const decryptData = (encryptedString) => {
    try {
      //When you decrypt using CryptoJS.AES.decrypt, the library uses the AES algorithm by default.
      const ENCRYPTION_KEY_BYTES = CryptoJS.enc.Base64.parse(ENCRYPTION_KEY_BASE64);
      const encryptedBytes = CryptoJS.enc.Base64.parse(encryptedString);

      const decrypted = CryptoJS.AES.decrypt(
        { ciphertext: encryptedBytes },
        ENCRYPTION_KEY_BYTES,
        { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 }
      );

      return decrypted.toString(CryptoJS.enc.Utf8);
    } catch (error) {
      console.error("Decryption error:", error);
      return "";
    }
  };
  //--------------------encryption ends----------------------------------------------//

  //create an arrow function that takes in an event (a click in this case)
  const handleGoogleLogin = async (e) => {
    const provider = new GoogleAuthProvider();

    // Enable the force prompt option
    provider.setCustomParameters({ prompt: 'select_account' });

    const userData = await signInWithPopup(auth, provider);

    if(userData.user.accessToken && allowedEmails.includes(userData.user.email)){
      setIsLoggedIn(true);

      setData({
        name: userData.user.displayName,
        img: userData.user.photoURL,
        email: userData.user.email,
        accessToken: userData.user.accessToken
      })

      navigate('/home');
    } else {
      if(!userData.user.accessToken){
        console.log('Access token missing.');
      } else{
        console.log(`Email ${userData.user.email} used is not whitelisted.`);
      }
      
      setIsLoggedIn(false);
    }
  };

  return (
    <div className="login-container">
      <h2 className="banner">Mwimbwaniriuwa</h2>
      <img src={logo} alt="Logo" className="circle-banner-image"/>
      <GoogleButton 
        style={{
          borderRadius: '5px',
          padding: '1px',
          marginTop: '2rem',
          overflow: 'hidden',
        }} 
        onClick={handleGoogleLogin} />
    </div>
  );
};

export default Login;
