import React, {useEffect, useState, useContext} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {Button, Container, Form, FormGroup, Input, Label} from 'reactstrap';
import AppNavbar from '../components/AppNavbar';
import User from '../context/ContextApi';

const LoanDefaulters = () => {
  const { data } = useContext(User);
  const navigate = useNavigate();

    const initialFormState = {
        clearanceDate: '',
        daysDefaulted: '',
        idNumber: '',
        issuanceDate: '',
        loanAmount: '',
        loanBalance: '',
        name: '',
        paidPenalty: '',
        penaltyPercentage: '',
        pendingPenalty: '',
        phoneNumber: '',
        repaymentDate: '',
        status: '',
        loanIssuance: {
            id: '',
            idNumber: '',
            issuanceDate: '',
            loanAmount: '',
            loanInterestAmount: '',
            loanInterestPaymentStatus: '',
            loanBalance: '',
            loanDisbursedAmount: '',
            loaneeImage: '',
            name: '',
            phoneNumber: '',
            repaymentDate: '',
            residence: '',
            company: {id: '', name: '', status:''},
            lendingStation: {
                id: '', 
                name: '', 
                company: {id: '', name: '', status:''}, 
                status:''},
            stationCoordinator: {
                id: '',
                idNumber: '',
                name: '',
                lendingStation: {
                    id: '',
                    name: '',
                    company: {id: '', name: '', status:''},
                    status:''
                },
                company: {id: '', name: '', status:''},
                status: '',
                registrationDate: '',
                deregistrationDate: ''
            }
          }
        };      

      const [loandefaulters, setLoandefaulters] = useState(initialFormState);
      const {id} = useParams();

      useEffect(() => {
        if(id !== 'new'){
            fetch(`/api/v1/loandefaulters/${id}`)
            .then(response => response.json())
            .then(data => setLoandefaulters(data));
        }
      }, [id, setLoandefaulters]);

      useEffect(() => {
        if (!data.accessToken) {
          navigate('/'); // Redirect to login if not authenticated
        }
      }, [data.accessToken, navigate]);

      const getFormattedCurrentDate = () => {
        const currentDate = new Date();
        const offset = currentDate.getTimezoneOffset() * 60000; // Get the offset in milliseconds
    
        // Adjust the current date and time by adding the offset for GMT+3
        const adjustedCurrentDate = new Date(currentDate.getTime() - offset);
    
        return adjustedCurrentDate;
      };

      const handleChange = (event) => {
        const { name, value } = event.target;
        const updatedLoanDefaulters = { ...loandefaulters };
      
        //Handle nested loanIssuance object
        if (name.startsWith('loanIssuance.')) {
            const outermostNestedName = name.split('.')[1];
            // Handle nested company & lendingStation objects within stationCoordinator
            if (outermostNestedName.startsWith('stationCoordinator.')) {
                const outerNestedName = outermostNestedName.split('.')[1];
                // Handle nested lendingStation within stationCoordinator object
                if (outerNestedName.startsWith('stationCoordinator.lendingStation.')) {
                    const nestedName = outerNestedName.split('.')[1];
                    // Handle nested company within lendingStation object
                    if (nestedName.startsWith('stationCoordinator.lendingStation.company.')) {
                        const nestednestedName = nestedName.split('.')[1];
                        updatedLoanDefaulters.lendingStation.company[nestednestedName] = value;
                    } else {
                        updatedLoanDefaulters.lendingStation[nestedName] = value;
                    }            
                } 
                // Handle nested company within stationCoordinator object
                else if (outerNestedName.startsWith('stationCoordinator.company.')) {
                    const nestednestedName = outerNestedName.split('.')[1];
                    updatedLoanDefaulters.lendingStation.company[nestednestedName] = value;
                } else {
                    updatedLoanDefaulters.lendingStation[outerNestedName] = value;
                }            
            } else if (outermostNestedName.startsWith('lendingStation.')) {
                const nestedName = outermostNestedName.split('.')[1];
                // Handle nested company within lendingStation object
                if (nestedName.startsWith('lendingStation.company.')) {
                    const nestednestedName = nestedName.split('.')[1];
                    updatedLoanDefaulters.lendingStation.company[nestednestedName] = value;
                } else {
                    updatedLoanDefaulters.lendingStation[nestedName] = value;
                }            
            } else if (outermostNestedName.startsWith('company.')) {
                const nestedName = outermostNestedName.split('.')[1];
                updatedLoanDefaulters.company[nestedName] = value;
            }
        } else {
            updatedLoanDefaulters[name] = value;
        }
        
        if(id === 'new'){       
          const adjustedCurrentDate = getFormattedCurrentDate();
          updatedLoanDefaulters.issuanceDate = adjustedCurrentDate.toISOString();

          // Calculate repaymentDate by adding 7 days' worth of milliseconds
          const repaymentDateTimestamp = adjustedCurrentDate.getTime() + (7 * 24 * 60 * 60 * 1000);
          const repaymentDate = new Date(repaymentDateTimestamp);          

          const formattedRepaymentDate = repaymentDate.toISOString();
          updatedLoanDefaulters.repaymentDate = formattedRepaymentDate;
        }

        setLoandefaulters(updatedLoanDefaulters);
      };      

      const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await fetch(`/api/v1/loandefaulters${loandefaulters.id ? `/${loandefaulters.id}` : ''}`, {
            method: (loandefaulters.id) ? 'PUT' : 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(loandefaulters)
            });
            
            setLoandefaulters(initialFormState);
            navigate('/loandefaulters');

            if (response.ok) {
            // Handle the successful response, e.g., show a success message
            console.log('Loan Defaulters created successfully!');
            } else {
            // Handle the error response, e.g., show an error message
            console.error('Error creating Loan Defaulters else block:', response.statusText);
            }
        } catch (error) {
            // Handle any network or other errors
            console.error('Error creating Loan Defaulters catch block:', error);
        }
      }
      
      const formattedCurrentDate = getFormattedCurrentDate().toISOString();

      const title = <h2>{loandefaulters.id ? 'Edit Loan Defaulters' : 'Add Loan Defaulters'}</h2>;
    
      return (<div>
          <AppNavbar/>
          <Container>
            {title}
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label for="name">Name</Label>
                <Input type="text" name="name" id="name" value={loandefaulters.name || ''}
                       onChange={handleChange} autoComplete="name" disabled/>
              </FormGroup>
              <FormGroup>
                <Label for="idNumber">ID Number</Label>
                <Input type="text" name="idNumber" id="idNumber" value={loandefaulters.idNumber || ''}
                       onChange={handleChange} autoComplete="idNumber" disabled/>
              </FormGroup>
              <FormGroup>
                <Label for="phoneNumber">Phone Number</Label>
                <Input type="text" name="phoneNumber" id="phoneNumber" value={loandefaulters.phoneNumber || ''}
                       onChange={handleChange} autoComplete="contact-level1" disabled/>
              </FormGroup>
              <FormGroup>
                <Label for="loanAmount">Loan Amount</Label>
                <Input type="text" name="loanAmount" id="loanAmount" value={loandefaulters.loanAmount || ''}
                       onChange={handleChange} autoComplete="amount" disabled/>
              </FormGroup>
              <FormGroup>
                <Label for="loanBalance">Loan Balance</Label>
                <Input type="text" name="loanBalance" id="loanBalance" value={loandefaulters.loanBalance || '0.0'}
                       onChange={handleChange} autoComplete="amount" disabled/>
              </FormGroup>
              <FormGroup>
                <Label for="issuanceDate">Issuance Date</Label>
                <Input type="text" name="issuanceDate" id="issuanceDate" value={loandefaulters.issuanceDate || ''}
                       onChange={handleChange} autoComplete="date" disabled/>
              </FormGroup>
              <FormGroup>
                <Label for="repaymentDate">Repayment Date</Label>
                <Input type="text" name="repaymentDate" id="repaymentDate" value={loandefaulters.repaymentDate || ''}
                       onChange={handleChange} autoComplete="date" disabled/>
              </FormGroup>
              <FormGroup>
                <Label for="paidPenalty">Paid Penalty</Label>
                <Input type="text" name="paidPenalty" id="paidPenalty" value={loandefaulters.paidPenalty || '0.0'}
                       onChange={handleChange} autoComplete="amount"/>
              </FormGroup>
              <FormGroup>
                <Label for="status">Status</Label>
                <Input type="text" name="status" id="status" value={loandefaulters.status || 'closed'}
                       onChange={handleChange} autoComplete="contact-level1"/>
              </FormGroup>
              <FormGroup>
                <Label for="clearanceDate">Clearance Date</Label>
                <Input type="text" name="clearanceDate" id="clearanceDate" value={formattedCurrentDate}
                       onChange={handleChange} autoComplete="contact-level1"/>
              </FormGroup>
              <FormGroup>
                <Label for="loanissuanceid">Loan Issuance Id</Label>
                <Input
                  type="text"
                  name="loanIssuance.id" // Update the name attribute to "loanIssuance.id"
                  id="loanissuanceid"
                  value={loandefaulters.loanIssuance?.id || '0'} // Ensure that the input field is initialized with the id value of the loanIssuance object, if it exists.
                  onChange={handleChange}
                  autoComplete="contact-level1"
                  disabled/>
              </FormGroup>              

              <FormGroup>
                <Button color="primary" type="submit">Save</Button>{' '}
                <Button color="secondary" tag={Link} to="/loandefaulters">Cancel</Button>
              </FormGroup>
            </Form>
          </Container>
        </div>
      )
};

export default LoanDefaulters;