import React, {useEffect, useState, useRef, useContext} from 'react';
import {Button, ButtonGroup, Container, Table, Pagination, PaginationItem, PaginationLink} from 'reactstrap';
import AppNavbar from '../components/AppNavbar';
import {Link, useNavigate } from 'react-router-dom';
import User from '../context/ContextApi';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';

const LoanRepaymentList = () => {
  const { data } = useContext(User);
  const navigate = useNavigate();

  const defaultNumber = -1;
  const emptyString = '';
  const defaultDate = '1900-01-01';
  const firstPage = 1;

  const [loanRepayments, setLoanRepayments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(firstPage);
  const [pageSize] = useState(5);
  const [totalCount, setTotalCount] = useState(0);
  const prevPage = useRef(firstPage);
  // New state variables for the search terms
  const [idFilter, setIdFilter] = useState(defaultNumber);
  const [idNumberFilter, setIdNumberFilter] = useState(defaultNumber);
  const [loanRepaymentAmountFilter, setLoanRepaymentAmountFilter] = useState(defaultNumber);
  const [loanRepaymentDateFilter, setLoanRepaymentDateFilter] = useState(defaultDate);
  const [loanIssuanceIdFilter, setLoanIssuanceIdFilter] = useState(defaultNumber);

  // State variable to store the timer reference
  const searchTimer = useRef(null);

  const debouncedSearchTimeoutInMs = 1000;

  useEffect(() => {
    // Clear the previous timer before setting a new one
    if (searchTimer.current) {
      clearTimeout(searchTimer.current);
    }

    // Set a new timer to call the fetchData function after a delay
    searchTimer.current = setTimeout(() => {
    setLoading(true);

    const pageNo = currentPage  - 1;

    const idPlacer = idFilter === defaultNumber ? emptyString : `&id=${encodeURIComponent(idFilter)}`;
    const idNumberPlacer = idNumberFilter === defaultNumber ? emptyString : `&idNumber=${encodeURIComponent(idNumberFilter)}`;
    const loanRepaymentAmountPlacer = loanRepaymentAmountFilter === defaultNumber ? emptyString : `&loanRepaymentAmount=${encodeURIComponent(loanRepaymentAmountFilter)}`;
    const loanRepaymentDatePlacer = loanRepaymentDateFilter === defaultDate ? emptyString : `&loanRepaymentDate=${encodeURIComponent(loanRepaymentDateFilter)}`;
    const loanIssuanceIdPlacer = loanIssuanceIdFilter === defaultNumber ? emptyString : `&loanIssuanceId=${encodeURIComponent(loanIssuanceIdFilter)}`;

    const url = `api/v1/loanrepayments?pageNo=${pageNo}&pageSize=${pageSize}${idPlacer}${idNumberPlacer}${loanRepaymentAmountPlacer}${loanRepaymentDatePlacer}${loanIssuanceIdPlacer}`;

    // Clear the loanRepayments state if navigating to a non-sequential page
    if (currentPage !== prevPage.current) {
      setLoanRepayments([]);
    }

    fetch(url)
      .then(response => response.json())
      .then(data => {
        setLoanRepayments(prevLoanRepayments => (currentPage === firstPage ? data.loanRepayments : [...prevLoanRepayments, ...data.loanRepayments]));
        setTotalCount(data.totalCount);
        setLoading(false);
      });

      prevPage.current = currentPage;  // Update the previous page
    }, debouncedSearchTimeoutInMs); // Adjust the time (in milliseconds) based on your preference

    // Clear the timer when the component unmounts or when the search term changes
    return () => clearTimeout(searchTimer.current);
  }, [defaultNumber, currentPage, pageSize, idFilter, idNumberFilter, loanRepaymentAmountFilter, loanRepaymentDateFilter, loanIssuanceIdFilter]);

  useEffect(() => {
    if (!data.accessToken) {
      navigate('/'); // Redirect to login if not authenticated
    }
  }, [data.accessToken, navigate]);

  // Handle the input changes and set the search terms
  const handleIdChange = (e) => {
    setIdFilter((!e.target.value || e.target.value === '-') ? defaultNumber : e.target.value);
    setCurrentPage(firstPage);
  };
  
  const handleIdNumberChange = (e) => {
    setIdNumberFilter((!e.target.value || e.target.value === '-') ? defaultNumber : e.target.value);
    setCurrentPage(firstPage);
  };

  const handleLoanRepaymentAmountChange = (e) => {
    setLoanRepaymentAmountFilter((!e.target.value || e.target.value === '-') ? defaultNumber : e.target.value);
    setCurrentPage(firstPage);
  };
  
  const handleLoanRepaymentDateChange = (e) => {
    setLoanRepaymentDateFilter(!e.target.value ? defaultDate : e.target.value);
    setCurrentPage(firstPage);
  };
  
  const handleLoanIssuanceIdChange = (e) => {
    setLoanIssuanceIdFilter((!e.target.value || e.target.value === '-') ? defaultNumber : e.target.value);
    setCurrentPage(firstPage);
  };

  const remove = async (id) => {
    await fetch(`api/v1/loanrepayments/${id}`, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(() => {
      let updatedLoanRepayments = [...loanRepayments].filter(i => i.id !== id);
      setLoanRepayments(updatedLoanRepayments);
    })
  }

  if(loading){
    return <p>Loading...</p>;
  }

  // Calculate indexes of the loanRepayments to be displayed on the current page
  const indexOfLastLoanRepayment = Math.min(currentPage * pageSize, loanRepayments.length);
  const indexOfFirstLoanRepayment = Math.max(indexOfLastLoanRepayment - pageSize, 0);
  const currentLoanRepayments = loanRepayments.slice(indexOfFirstLoanRepayment, indexOfLastLoanRepayment);

  const loanRepaymentList = currentLoanRepayments.map(loanRepayment => {
    if (!loanRepayment) {
        return null; // Skip rendering if the loanRepayment is undefined or null
    }
    
    return <tr key={loanRepayment.id}>
        <td style={{whiteSpace: 'nowrap'}}>{loanRepayment.id}</td>
        <td style={{whiteSpace: 'nowrap'}}>{loanRepayment.idNumber}</td>
        <td style={{whiteSpace: 'nowrap'}}>{loanRepayment.loanRepaymentAmount}</td>
        <td style={{whiteSpace: 'nowrap'}}>{loanRepayment.loanRepaymentDate}</td>
        <td style={{whiteSpace: 'nowrap'}}>
            {loanRepayment.loanIssuance ? loanRepayment.loanIssuance.id : emptyString}
        </td>
        <td>
            <ButtonGroup>
                <Button size="sm" color="primary" tag={Link} to={"/loanRepayments/"+loanRepayment.id} disabled>Edit</Button>
                <Button size="sm" color="danger" onClick={() => remove(loanRepayment.id)} disabled>Delete</Button>
            </ButtonGroup>
        </td>
    </tr>
  });

  // Change page
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Calculate total number of pages
  const totalPages = Math.ceil(totalCount / pageSize);

  library.add(faAngleDoubleLeft, faAngleDoubleRight);

  const renderPaginationItems = () => {
    const visiblePageCount = 5;
    const ellipsis = <span>...</span>;
  
    if (totalPages <= visiblePageCount) {
      return Array.from({ length: totalPages }, (_, i) => (
        <PaginationItem key={i} active={i + 1 === currentPage}>
          <PaginationLink onClick={() => handlePageChange(i + 1)}>{i + 1}</PaginationLink>
        </PaginationItem>
      ));
    }
  
    const isStartEllipsisNeeded = currentPage > visiblePageCount - Math.floor(visiblePageCount / 2);
    const isEndEllipsisNeeded = currentPage < totalPages - Math.floor(visiblePageCount / 2);
  
    const paginationItems = [];
    let startPage = firstPage;
    let endPage = totalPages;
  
    if (isStartEllipsisNeeded) {
      startPage = currentPage - Math.floor(visiblePageCount / 2);
      paginationItems.push(
        <PaginationItem key="start-ellipsis" disabled>
          {ellipsis}
        </PaginationItem>
      );
    }
  
    if (isEndEllipsisNeeded) {
      endPage = currentPage + Math.floor(visiblePageCount / 2);
    }
  
    for (let i = startPage; i <= endPage; i++) {
      paginationItems.push(
        <PaginationItem key={i} active={i === currentPage}>
          <PaginationLink onClick={() => handlePageChange(i)}>{i}</PaginationLink>
        </PaginationItem>
      );
    }
  
    if (isEndEllipsisNeeded) {
      paginationItems.push(
        <PaginationItem key="end-ellipsis" disabled>
          {ellipsis}
        </PaginationItem>
      );
    }
  
    return paginationItems;
  };

  return (
    <div>
        <AppNavbar/>
        <Container fluid>
            <div className="float-end">
                <Button color="success" tag={Link} to="/loanRepayments/new" disabled>Add Loan Repayment</Button>
            </div>
            <h3>Loan Repayments</h3>
            <Table className="mt-4" responsive>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>ID Number</th>
                        <th>Loan Repayment Amount</th>
                        <th>Loan Repayment Date</th>
                        <th>Loan Issuance Id</th>
                        <th width="10%">Actions</th>
                    </tr>
                    <tr>
                        <td>
                          <input
                          type="text"
                          id="id"
                          value={idFilter}
                          onChange={handleIdChange}
                          style={{ width: '100px' }} />
                        </td>
                        <td>
                          <input
                          type="text"
                          id="idNumber"
                          value={idNumberFilter}
                          onChange={handleIdNumberChange}
                          style={{ width: '100px' }} />
                        </td>
                        <td>
                          <input
                          type="text"
                          id="loanRepaymentAmount"
                          value={loanRepaymentAmountFilter}
                          onChange={handleLoanRepaymentAmountChange}
                          style={{ width: '70px' }} />
                          </td>
                        <td>
                          <input
                          type="text"
                          id="loanRepaymentDate"
                          value={loanRepaymentDateFilter}
                          onChange={handleLoanRepaymentDateChange}
                          style={{ width: '100px' }} />
                          </td>
                        <td>
                          <input
                          type="text"
                          id="loanIssuanceId"
                          value={loanIssuanceIdFilter}
                          onChange={handleLoanIssuanceIdChange}
                          style={{ width: '70px' }} />
                        </td>
                        <td></td>
                    </tr>
                </thead>
                <tbody>
                    {loanRepaymentList}
                </tbody>
            </Table>
            {totalPages > 1 && (
            <Pagination>
              <PaginationItem disabled={currentPage === firstPage}>
                <PaginationLink onClick={() => handlePageChange(firstPage)}>
                  <FontAwesomeIcon icon="angle-double-left" />
                </PaginationLink>
              </PaginationItem>
              <PaginationItem disabled={currentPage === firstPage}>
                <PaginationLink onClick={() => handlePageChange(currentPage - 1)}>
                  Prev
                </PaginationLink>
              </PaginationItem>
              {renderPaginationItems()}
              <PaginationItem disabled={currentPage === totalPages}>
                <PaginationLink onClick={() => handlePageChange(currentPage + 1)}>
                  Next
                </PaginationLink>
              </PaginationItem>
              <PaginationItem disabled={currentPage === totalPages}>
                <PaginationLink onClick={() => handlePageChange(totalPages)}>
                  <FontAwesomeIcon icon="angle-double-right" />
                </PaginationLink>
              </PaginationItem>
            </Pagination>
            )}
        </Container>
    </div>
  );
}

export default LoanRepaymentList;
