import React, { useState } from 'react';
import './App.css';
import Home from './pages/Home';
import Login from './pages/Login';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CompanyList from './pages/CompanyList';
import CompanyEdit from './pages/CompanyEdit';
import InterestSettingList from './pages/InterestSettingList';
import InterestSettingEdit from './pages/InterestSettingEdit';
import LendingStationList from './pages/LendingStationList';
import LendingStationEdit from './pages/LendingStationEdit';
import StationCoordinatorList from './pages/StationCoordinatorList';
import StationCoordinatorEdit from './pages/StationCoordinatorEdit';
import LoanIssuanceList from './pages/LoanIssuanceList';
import LoanIssuanceEdit from './pages/LoanIssuanceEdit';
import LoanRepaymentList from './pages/LoanRepaymentList';
import LoanRepaymentEdit from './pages/LoanRepaymentEdit';
import LoanDefaultersList from './pages/LoanDefaultersList';
import LoanDefaultersEdit from './pages/LoanDefaultersEdit';
import User from './context/ContextApi';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [data, setData] = useState({
    name: "",
    img: "",
    email:"",
    accessToken: ""
  })

  return (
    <>
    <User.Provider value={{data, setData}}>
    <Router>
      <Routes>
        <Route path="/" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
        {isLoggedIn ? (
          <>
            <Route path="/home" element={<Home  isAuthenticated={isLoggedIn}/>} />
            <Route path="/companies" exact={true} element={<CompanyList />} />
            <Route path="/companies/:id" element={<CompanyEdit />} />
            <Route path="/interestsettings" exact={true} element={<InterestSettingList />} />
            <Route path="/interestsettings/:id" element={<InterestSettingEdit />} />
            <Route path="/lendingstations" exact={true} element={<LendingStationList />} />
            <Route path="/lendingstations/:id" element={<LendingStationEdit />} />
            <Route path="/stationcoordinators" exact={true} element={<StationCoordinatorList />} />
            <Route path="/stationcoordinators/:id" element={<StationCoordinatorEdit />} />
            <Route path="/loanissuances" exact={true} element={<LoanIssuanceList />} />
            <Route path="/loanissuances/:id" element={<LoanIssuanceEdit />} />
            <Route path="/loanrepayments" exact={true} element={<LoanRepaymentList />} />
            <Route path="/loanrepayments/:id" element={<LoanRepaymentEdit />} />
            <Route path="/loandefaulters" exact={true} element={<LoanDefaultersList />} />
            <Route path="/loandefaulters/:id" element={<LoanDefaultersEdit />} />
          </>
        ) : (
          <Route path="/" element={<Login setIsLoggedIn={() => setIsLoggedIn(false)} />} />
        )}
      </Routes>
    </Router>
    </User.Provider>
    </>    
  );
};

export default App;