import React, {useEffect, useState, useRef, useContext} from 'react';
import {Button, ButtonGroup, Container, Table, Pagination, PaginationItem, PaginationLink} from 'reactstrap';
import AppNavbar from '../components/AppNavbar';
import {Link, useNavigate } from 'react-router-dom';
import User from '../context/ContextApi';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';

const LoanIssuanceList = () => {
  const { data } = useContext(User);
  const navigate = useNavigate();

  const defaultNumber = -1;
  const emptyString = '';
  const defaultDate = '1900-01-01';
  const firstPage = 1;

  const [loanIssuances, setLoanIssuances] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(firstPage);
  const [pageSize] = useState(5);
  const [totalCount, setTotalCount] = useState(0);
  const prevPage = useRef(firstPage);
  // New state variables for the search terms
  const [idFilter, setIdFilter] = useState(defaultNumber);
  const [nameFilter, setNameFilter] = useState(emptyString);
  const [idNumberFilter, setIdNumberFilter] = useState(defaultNumber);
  const [phoneNumberFilter, setPhoneNumberFilter] = useState(defaultNumber);
  const [loanAmountFilter, setLoanAmountFilter] = useState(defaultNumber);
  const [loanBalanceFilter, setLoanBalanceFilter] = useState(defaultNumber);
  const [loanDisbursedAmountFilter, setLoanDisbursedAmountFilter] = useState(defaultNumber);
  const [pendingPenaltyFilter, setPendingPenaltyFilter] = useState(defaultNumber);
  const [balanceFilter, setBalanceFilter] = useState(defaultNumber);
  const [loanInterestAmountFilter, setLoanInterestAmountFilter] = useState(defaultNumber);
  const [loanInterestPaymentStatusFilter, setLoanInterestPaymentStatusFilter] = useState(emptyString);
  const [issuanceDateFilter, setIssuanceDateFilter] = useState(defaultDate);
  const [repaymentDateFilter, setRepaymentDateFilter] = useState(defaultDate);
  const [residenceFilter, setResidenceFilter] = useState(emptyString);
  const [stationCoordinatorIdFilter, setStationCoordinatorIdFilter] = useState(defaultNumber);
  const [lendingStationIdFilter, setLendingStationIdFilter] = useState(defaultNumber);
  const [companyIdFilter, setCompanyIdFilter] = useState(defaultNumber);

  // State variable to store the timer reference
  const searchTimer = useRef(null);

  const debouncedSearchTimeoutInMs = 1000;
  
  useEffect(() => {
    // Clear the previous timer before setting a new one
    if (searchTimer.current) {
      clearTimeout(searchTimer.current);
    }

    // Set a new timer to call the fetchData function after a delay
    searchTimer.current = setTimeout(() => {
      setLoading(true);

      const pageNo = currentPage  - 1;

      const idPlacer = idFilter === defaultNumber ? emptyString : `&id=${encodeURIComponent(idFilter)}`;
      const namePlacer = nameFilter === emptyString ? emptyString : `&name=${encodeURIComponent(nameFilter)}`;
      const idNumberPlacer = idNumberFilter === defaultNumber ? emptyString : `&idNumber=${encodeURIComponent(idNumberFilter)}`;
      const phoneNumberPlacer = phoneNumberFilter === defaultNumber ? emptyString : `&phoneNumber=${encodeURIComponent(phoneNumberFilter)}`;
      const loanAmountPlacer = loanAmountFilter === defaultNumber ? emptyString : `&loanAmount=${encodeURIComponent(loanAmountFilter)}`;
      const loanDisbursedAmountPlacer = loanDisbursedAmountFilter === defaultNumber ? emptyString : `&loanDisbursedAmount=${encodeURIComponent(loanDisbursedAmountFilter)}`;
      const loanBalancePlacer = loanBalanceFilter === defaultNumber ? emptyString : `&loanBalance=${encodeURIComponent(loanBalanceFilter)}`;
      const loanInterestAmountPlacer = loanInterestAmountFilter === defaultNumber ? emptyString : `&loanInterestAmount=${encodeURIComponent(loanInterestAmountFilter)}`;
      const loanInterestPaymentStatusPlacer = loanInterestPaymentStatusFilter === emptyString ? emptyString : `&loanInterestPaymentStatus=${encodeURIComponent(loanInterestPaymentStatusFilter)}`;
      const issuanceDatePlacer = issuanceDateFilter === defaultDate ? emptyString : `&issuanceDate=${encodeURIComponent(issuanceDateFilter)}`;
      const repaymentDatePlacer = repaymentDateFilter === defaultDate ? emptyString : `&repaymentDate=${encodeURIComponent(repaymentDateFilter)}`;
      const residencePlacer = residenceFilter === emptyString ? emptyString : `&residence=${encodeURIComponent(residenceFilter)}`;
      const pendingPenaltyPlacer = pendingPenaltyFilter === defaultNumber ? emptyString : `&pendingPenalty=${encodeURIComponent(pendingPenaltyFilter)}`;
      const balancePlacer = balanceFilter === defaultNumber ? emptyString : `&balance=${encodeURIComponent(balanceFilter)}`;
      const stationCoordinatorIdPlacer = stationCoordinatorIdFilter === defaultNumber ? emptyString : `&stationCoordinatorId=${encodeURIComponent(stationCoordinatorIdFilter)}`;
      const lendingStationIdPlacer = lendingStationIdFilter === defaultNumber ? emptyString : `&lendingStationId=${encodeURIComponent(lendingStationIdFilter)}`;
      const companyIdPlacer = companyIdFilter === defaultNumber ? emptyString : `&companyId=${encodeURIComponent(companyIdFilter)}`;

      const url = `api/v1/loanissuances?pageNo=${pageNo}&pageSize=${pageSize}${idPlacer}${namePlacer}${idNumberPlacer}${phoneNumberPlacer}${loanAmountPlacer}${loanDisbursedAmountPlacer}${loanBalancePlacer}${loanInterestAmountPlacer}${loanInterestPaymentStatusPlacer}${issuanceDatePlacer}${repaymentDatePlacer}${residencePlacer}${pendingPenaltyPlacer}${balancePlacer}${stationCoordinatorIdPlacer}${lendingStationIdPlacer}${companyIdPlacer}`;

      // Clear the loanIssuances state if navigating to a non-sequential page
      if (currentPage !== prevPage.current) {
        setLoanIssuances([]);
      }

      // Perform the API call and update state
      fetch(url)
        .then(response => response.json())
        .then(data => {
          setLoanIssuances(prevLoanIssuances => (currentPage === 1 ? data.loanIssuances : [...prevLoanIssuances, ...data.loanIssuances]));
          setTotalCount(data.totalCount);
          setLoading(false);
        });

      prevPage.current = currentPage; // Update the previous page
    }, debouncedSearchTimeoutInMs); // Adjust the time (in milliseconds) based on your preference

    // Clear the timer when the component unmounts or when the search term changes
    return () => clearTimeout(searchTimer.current);
  }, [defaultNumber, currentPage, pageSize, idFilter, nameFilter, idNumberFilter, phoneNumberFilter, loanAmountFilter, loanBalanceFilter, loanDisbursedAmountFilter, loanInterestAmountFilter, loanInterestPaymentStatusFilter, issuanceDateFilter, repaymentDateFilter, residenceFilter, pendingPenaltyFilter, balanceFilter, stationCoordinatorIdFilter, lendingStationIdFilter, companyIdFilter]);

  useEffect(() => {
    if (!data.accessToken) {
      navigate('/'); // Redirect to login if not authenticated
    }
  }, [data.accessToken, navigate]);

  // Handle the input changes and set the search terms
  const handleIdChange = (e) => {
    setIdFilter((!e.target.value || e.target.value === '-') ? defaultNumber : e.target.value);
    setCurrentPage(firstPage);
  };

  const handleNameChange = (e) => {
    setNameFilter(e.target.value);
    setCurrentPage(firstPage);
  };
  
  const handleIdNumberChange = (e) => {
    setIdNumberFilter((!e.target.value || e.target.value === '-') ? defaultNumber : e.target.value);
    setCurrentPage(firstPage);
  };
  
  const handlePhoneNumberChange = (e) => {
    setPhoneNumberFilter((!e.target.value || e.target.value === '-') ? defaultNumber : e.target.value);
    setCurrentPage(firstPage);
  };
  
  const handleLoanAmountChange = (e) => {
    setLoanAmountFilter((!e.target.value || e.target.value === '-') ? defaultNumber : e.target.value);
    setCurrentPage(firstPage);
  };
  
  const handleLoanBalanceChange = (e) => {
    setLoanBalanceFilter((!e.target.value || e.target.value === '-') ? defaultNumber : e.target.value);
    setCurrentPage(firstPage);
  };
  
  const handleLoanDisbursedAmountChange = (e) => {
    setLoanDisbursedAmountFilter((!e.target.value || e.target.value === '-') ? defaultNumber : e.target.value);
    setCurrentPage(firstPage);
  };
  
  const handleLoanInterestAmountChange = (e) => {
    setLoanInterestAmountFilter((!e.target.value || e.target.value === '-') ? defaultNumber : e.target.value);
    setCurrentPage(firstPage);
  };
  
  const handleLoanInterestPaymentStatusChange = (e) => {
    setLoanInterestPaymentStatusFilter(!e.target.value ? emptyString : e.target.value);
    setCurrentPage(firstPage);
  };

  const handleIssuanceDateChange = (e) => {
    setIssuanceDateFilter(!e.target.value ? defaultDate : e.target.value);
    setCurrentPage(firstPage);
  };

  const handleRepaymentDateChange = (e) => {
    setRepaymentDateFilter(!e.target.value ? defaultDate : e.target.value);
    setCurrentPage(firstPage);
  };

  const handleResidenceChange = (e) => {
    setResidenceFilter(e.target.value);
    setCurrentPage(firstPage);
  };
  
  const handlePendingPenaltyChange = (e) => {
    setPendingPenaltyFilter((!e.target.value || e.target.value === '-') ? defaultNumber : e.target.value);
    setCurrentPage(firstPage);
  };
  
  const handleBalanceChange = (e) => {
    setBalanceFilter((!e.target.value || e.target.value === '-') ? defaultNumber : e.target.value);
    setCurrentPage(firstPage);
  };
  
  const handleStationCoordinatorIdChange = (e) => {
    setStationCoordinatorIdFilter((!e.target.value || e.target.value === '-') ? defaultNumber : e.target.value);
    setCurrentPage(firstPage);
  };
  
  const handleLendingStationIdChange = (e) => {
    setLendingStationIdFilter((!e.target.value || e.target.value === '-') ? defaultNumber : e.target.value);
    setCurrentPage(firstPage);
  };
  
  const handleCompanyIdChange = (e) => {
    setCompanyIdFilter((!e.target.value || e.target.value === '-') ? defaultNumber : e.target.value);
    setCurrentPage(firstPage);
  };

  const remove = async (id) => {
    await fetch(`api/v1/loanissuances/${id}`, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(() => {
      let updatedLoanIssuances = [...loanIssuances].filter(i => i.id !== id);
      setLoanIssuances(updatedLoanIssuances);
    })
  }

  if(loading){
    return <p>Loading...</p>;
  }

  // Calculate indexes of the loanIssuances to be displayed on the current page
  const indexOfLastLoanIssuance = Math.min(currentPage * pageSize, loanIssuances.length);
  const indexOfFirstLoanIssuance = Math.max(indexOfLastLoanIssuance - pageSize, 0);
  const currentLoanIssuances = loanIssuances.slice(indexOfFirstLoanIssuance, indexOfLastLoanIssuance);

  const loanIssuanceList = currentLoanIssuances.map(loanIssuance => {
    if (!loanIssuance) {
        return null; // Skip rendering if the loanIssuance is undefined or null
    }

    return <tr key={loanIssuance.id}>
        <td style={{whiteSpace: 'nowrap'}}>{loanIssuance.id}</td>
        <td style={{whiteSpace: 'nowrap'}}>{loanIssuance.name}</td>
        <td style={{whiteSpace: 'nowrap'}}>{loanIssuance.idNumber}</td>
        <td style={{whiteSpace: 'nowrap'}}>{loanIssuance.phoneNumber}</td>
        <td style={{whiteSpace: 'nowrap'}}>{loanIssuance.loanAmount}</td>
        <td style={{whiteSpace: 'nowrap'}}>{loanIssuance.loanBalance}</td>
        <td style={{whiteSpace: 'nowrap'}}>{loanIssuance.loanDisbursedAmount}</td>
        <td style={{whiteSpace: 'nowrap'}}>{loanIssuance.loanInterestAmount}</td>
        <td style={{whiteSpace: 'nowrap'}}>{loanIssuance.loanInterestPaymentStatus}</td>
        <td style={{whiteSpace: 'nowrap'}}>{loanIssuance.pendingPenalty}</td>
        <td style={{whiteSpace: 'nowrap'}}>{loanIssuance.balance}</td>
        <td style={{whiteSpace: 'nowrap'}}><img src={loanIssuance.loaneeImageUrl} alt={`${loanIssuance.id}`} style={{ maxWidth: '200px', maxHeight: '200px' }}/></td>
        <td style={{whiteSpace: 'nowrap'}}><img src={loanIssuance.loaneeIdImageUrl} alt={`${loanIssuance.id}`} style={{ maxWidth: '200px', maxHeight: '200px' }}/></td>
        <td style={{whiteSpace: 'nowrap'}}>{loanIssuance.issuanceDate}</td>
        <td style={{whiteSpace: 'nowrap'}}>{loanIssuance.repaymentDate}</td>
        <td style={{whiteSpace: 'nowrap'}}>{loanIssuance.residence}</td>
        <td style={{whiteSpace: 'nowrap'}}>
            {loanIssuance.company ? loanIssuance.company.id : emptyString}
        </td>
        <td style={{whiteSpace: 'nowrap'}}>
            {loanIssuance.lendingStation ? loanIssuance.lendingStation.id : emptyString}
        </td>
        <td style={{whiteSpace: 'nowrap'}}>
            {loanIssuance.stationCoordinator ? loanIssuance.stationCoordinator.id : emptyString}
        </td>
        <td>
            <ButtonGroup>
                <Button size="sm" color="primary" tag={Link} to={"/loanissuances/"+loanIssuance.id} disabled>Edit</Button>
                <Button size="sm" color="danger" onClick={() => remove(loanIssuance.id)} disabled>Delete</Button>
            </ButtonGroup>
        </td>
    </tr>
  }); 

  // Change page
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Calculate total number of pages
  const totalPages = Math.ceil(totalCount / pageSize);

  library.add(faAngleDoubleLeft, faAngleDoubleRight);

  const renderPaginationItems = () => {
    const visiblePageCount = 5;
    const ellipsis = <span>...</span>;
  
    if (totalPages <= visiblePageCount) {
      return Array.from({ length: totalPages }, (_, i) => (
        <PaginationItem key={i} active={i + 1 === currentPage}>
          <PaginationLink onClick={() => handlePageChange(i + 1)}>{i + 1}</PaginationLink>
        </PaginationItem>
      ));
    }
  
    const isStartEllipsisNeeded = currentPage > visiblePageCount - Math.floor(visiblePageCount / 2);
    const isEndEllipsisNeeded = currentPage < totalPages - Math.floor(visiblePageCount / 2);
  
    const paginationItems = [];
    let startPage = firstPage;
    let endPage = totalPages;
  
    if (isStartEllipsisNeeded) {
      startPage = currentPage - Math.floor(visiblePageCount / 2);
      paginationItems.push(
        <PaginationItem key="start-ellipsis" disabled>
          {ellipsis}
        </PaginationItem>
      );
    }
  
    if (isEndEllipsisNeeded) {
      endPage = currentPage + Math.floor(visiblePageCount / 2);
    }
  
    for (let i = startPage; i <= endPage; i++) {
      paginationItems.push(
        <PaginationItem key={i} active={i === currentPage}>
          <PaginationLink onClick={() => handlePageChange(i)}>{i}</PaginationLink>
        </PaginationItem>
      );
    }
  
    if (isEndEllipsisNeeded) {
      paginationItems.push(
        <PaginationItem key="end-ellipsis" disabled>
          {ellipsis}
        </PaginationItem>
      );
    }
  
    return paginationItems;
  };  

  return (
    <div>
        <AppNavbar/>
        <Container fluid>
            <div className="float-end">
                <Button color="success" tag={Link} to="/loanissuances/new" disabled>Add Loan Issued</Button>
            </div>
            <h3>Loans Issued</h3>
            <Table className="mt-4" responsive>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th width="10%">Name</th>
                        <th>ID Number</th>
                        <th width="5%">Phone Number</th>
                        <th>Loan Amount</th>
                        <th>Loan Balance</th>
                        <th>Disbursed Amount</th>
                        <th>Interest Amount</th>
                        <th>Interest Status</th>
                        <th>Pending Penalty</th>
                        <th>Balance</th>
                        <th>Loanee Image</th>
                        <th>Loanee ID Card</th>
                        <th>Issuance Date</th>
                        <th>Maturity Date</th>
                        <th width="10%">Residence</th>
                        <th>Company Id</th>
                        <th>Lending Station Id</th>
                        <th>Station Coordinator Id</th>
                        <th width="5%">Actions</th>
                    </tr>
                    <tr>
                        <td>
                          <input
                          type="text"
                          id="id"
                          value={idFilter}
                          onChange={handleIdChange}
                          style={{ width: '100px' }} />
                        </td>
                        <td>
                          <input
                          type="text"
                          id="name" 
                          value={nameFilter}
                          onChange={handleNameChange}
                          style={{ width: '200px' }} />
                        </td>
                        <td>
                          <input
                          type="text"
                          id="idNumber"
                          value={idNumberFilter}
                          onChange={handleIdNumberChange}
                          style={{ width: '100px' }} />
                        </td>
                        <td>
                          <input
                          type="text"
                          id="phoneNumber"
                          value={phoneNumberFilter}
                          onChange={handlePhoneNumberChange}
                          style={{ width: '100px' }} />
                          </td>
                        <td>
                          <input
                          type="text"
                          id="loanAmount"
                          value={loanAmountFilter}
                          onChange={handleLoanAmountChange}
                          style={{ width: '70px' }} />
                          </td>
                        <td>
                          <input
                          type="text"
                          id="loanBalance"
                          value={loanBalanceFilter}
                          onChange={handleLoanBalanceChange}
                          style={{ width: '70px' }} />
                        </td>
                        <td>
                          <input
                          type="text"
                          id="loanDisbursedAmount"
                          value={loanDisbursedAmountFilter}
                          onChange={handleLoanDisbursedAmountChange}
                          style={{ width: '70px' }} />
                        </td>
                        <td>
                          <input
                          type="text"
                          id="loanInterestAmount"
                          value={loanInterestAmountFilter}
                          onChange={handleLoanInterestAmountChange}
                          style={{ width: '70px' }} />
                        </td>
                        <td>
                          <input
                          type="text"
                          id="loanInterestPaymentStatus"
                          value={loanInterestPaymentStatusFilter}
                          onChange={handleLoanInterestPaymentStatusChange}
                          style={{ width: '70px' }} />
                        </td>
                        <td>
                          <input
                          type="text"
                          id="pendingPenalty"
                          value={pendingPenaltyFilter}
                          onChange={handlePendingPenaltyChange}
                          style={{ width: '70px' }} />
                        </td>
                        <td>
                          <input
                          type="text"
                          id="balance"
                          value={balanceFilter}
                          onChange={handleBalanceChange}
                          style={{ width: '70px' }} />
                        </td>
                        <td></td>
                        <td></td>
                        <td>
                          <input
                          type="text"
                          id="issuanceDate"
                          value={issuanceDateFilter}
                          onChange={handleIssuanceDateChange}
                          style={{ width: '100px' }} />
                        </td>
                        <td>
                          <input
                          type="text"
                          id="repaymentDate"
                          value={repaymentDateFilter}
                          onChange={handleRepaymentDateChange}
                          style={{ width: '100px' }} />
                        </td>
                        <td>
                          <input
                          type="text"
                          id="residence" 
                          value={residenceFilter}
                          onChange={handleResidenceChange}
                          style={{ width: '200px' }} />
                          </td>
                        <td>
                          <input
                          type="text"
                          id="companyId"
                          value={companyIdFilter}
                          onChange={handleCompanyIdChange}
                          style={{ width: '50px' }} />
                        </td>
                        <td>
                          <input
                          type="text"
                          id="lendingStationId"
                          value={lendingStationIdFilter}
                          onChange={handleLendingStationIdChange}
                          style={{ width: '50px' }} />
                        </td>
                        <td>
                          <input
                          type="text"
                          id="stationCoordinatorId"
                          value={stationCoordinatorIdFilter}
                          onChange={handleStationCoordinatorIdChange}
                          style={{ width: '50px' }} />
                        </td>
                        <td></td>
                    </tr>
                </thead>
                <tbody>
                    {loanIssuanceList}
                </tbody>
            </Table>
            {totalPages > 1 && (
            <Pagination>
              <PaginationItem disabled={currentPage === firstPage}>
                <PaginationLink onClick={() => handlePageChange(firstPage)}>
                  <FontAwesomeIcon icon="angle-double-left" />
                </PaginationLink>
              </PaginationItem>
              <PaginationItem disabled={currentPage === firstPage}>
                <PaginationLink onClick={() => handlePageChange(currentPage - 1)}>
                  Prev
                </PaginationLink>
              </PaginationItem>
              {renderPaginationItems()}
              <PaginationItem disabled={currentPage === totalPages}>
                <PaginationLink onClick={() => handlePageChange(currentPage + 1)}>
                  Next
                </PaginationLink>
              </PaginationItem>
              <PaginationItem disabled={currentPage === totalPages}>
                <PaginationLink onClick={() => handlePageChange(totalPages)}>
                  <FontAwesomeIcon icon="angle-double-right" />
                </PaginationLink>
              </PaginationItem>
            </Pagination>
            )}
        </Container>
    </div>
  );
}

export default LoanIssuanceList;