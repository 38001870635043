import './AppNavbar.css';
import React, {useState} from'react';
import { Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import { mySignOut } from "../firebase/firebaseConfig";
import { useContext } from 'react';
import User from '../context/ContextApi';

const AppNavbar = () => {
    const {data, setData} = useContext(User);
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();

    const welcome = `Welcome, ${data.name} `;

    const handleLogout = () => {
        mySignOut.then(() => {
            // Successful logout
            console.log("Successful logout");

            // localStorage.clear();
            setData({
                name: "",
                img: "",
                email:"",
                accessToken: ""
              });

            // Redirect to landing page
            navigate('/');
          })
          .catch((error) => {
            // Handle logout error
            console.log('Logout error:', error);
          });
      };
  
    return (
        <Navbar color="dark" dark expand="md">
            <NavbarBrand tag={Link} to="/home">Home</NavbarBrand>
            <NavbarBrand tag={Link} to="/" onClick={handleLogout}>Logout</NavbarBrand>
            <NavbarToggler onClick={() => {setIsOpen(!isOpen)}}/>
            <Collapse isOpen={isOpen} navbar>
                <Nav className="justify-content-end" style={{width: "100%"}} navbar>
                    <NavItem>
                        <NavLink>
                            {welcome} 
                            <img src={data.img || ""} 
                            className="circle-image"  
                            alt="profile"/></NavLink>
                    </NavItem>
                </Nav>
            </Collapse>
        </Navbar>
    );
};

export default AppNavbar;