import React, {useEffect, useState, useContext} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {Button, Container, Form, FormGroup, Input, Label} from 'reactstrap';
import AppNavbar from '../components/AppNavbar';
import User from '../context/ContextApi';

const InterestSettingEdit = () => {
  const { data } = useContext(User);
  const navigate = useNavigate();

      const initialFormState = {
        interestRate: '',
        interestTerm: '',
        company: {id: '', name: '', status:''}
      };

      const [interestsetting, setInterestsetting] = useState(initialFormState);
      const {id} = useParams();

      useEffect(() => {
        if(id !== 'new'){
            fetch(`/api/v1/interestsettings/${id}`)
            .then(response => response.json())
            .then(data => setInterestsetting(data));
        }
      }, [id, setInterestsetting]);

      useEffect(() => {
        if (!data.accessToken) {
          navigate('/'); // Redirect to login if not authenticated
        }
      }, [data.accessToken, navigate]);

      const handleChange = (event) => {
        const { name, value } = event.target;
        const updatedInterestSetting = { ...interestsetting };
      
        // Handle nested company object
        if (name.startsWith('company.')) {
          const nestedName = name.split('.')[1];
          updatedInterestSetting.company[nestedName] = value;
        } else {
          updatedInterestSetting[name] = value;
        }
      
        setInterestsetting(updatedInterestSetting);
      };
      

      const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await fetch(`/api/v1/interestsettings${interestsetting.id ? `/${interestsetting.id}` : ''}`, {
            method: (interestsetting.id) ? 'PUT' : 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(interestsetting)
            });
            
            setInterestsetting(initialFormState);
            navigate('/interestsettings');

            if (response.ok) {
            // Handle the successful response, e.g., show a success message
            console.log('Interest Setting created successfully!');
            } else {
            // Handle the error response, e.g., show an error message
            console.error('Error creating Interest Setting else block:', response.statusText);
            }
        } catch (error) {
            // Handle any network or other errors
            console.error('Error creating Interest Setting catch block:', error);
        }
      }

      const title = <h2>{interestsetting.id ? 'Edit Interest Setting' : 'Add Interest Setting'}</h2>;
    
      return (<div>
          <AppNavbar/>
          <Container>
            {title}
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label for="interestRate">Interest Rate</Label>
                <Input type="text" name="interestRate" id="interestRate" value={interestsetting.interestRate || ''}
                       onChange={handleChange} autoComplete="name"/>
              </FormGroup>
              <FormGroup>
                <Label for="interestTerm">Interest Term</Label>
                <Input type="text" name="interestTerm" id="interestTerm" value={interestsetting.interestTerm || ''}
                       onChange={handleChange} autoComplete="contact-level1"/>
              </FormGroup>
              <FormGroup>
                <Label for="companyid">companyid</Label>
                <Input
                  type="text"
                  name="company.id" // Update the name attribute to "company.id"
                  id="companyid"
                  value={interestsetting.company?.id || ''} // Ensure that the input field is initialized with the id value of the company object, if it exists.
                  onChange={handleChange}
                  autoComplete="contact-level1"
                />
              </FormGroup>

              <FormGroup>
                <Button color="primary" type="submit">Save</Button>{' '}
                <Button color="secondary" tag={Link} to="/interestsettings">Cancel</Button>
              </FormGroup>
            </Form>
          </Container>
        </div>
      )
};

export default InterestSettingEdit;