import React, {useEffect, useState, useContext} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {Button, Container, Form, FormGroup, Input, Label} from 'reactstrap';
import AppNavbar from '../components/AppNavbar';
import User from '../context/ContextApi';

const LendingStationEdit = () => {
  const { data } = useContext(User);
  const navigate = useNavigate();

      const initialFormState = {
        name: '',
        status: '',
        company: {id: '', name: '', status:''}
      };

      const [nameError, setNameError] = useState('');
      const [statusError, setStatusError] = useState('');

      const [lendingstation, setLendingstation] = useState(initialFormState);
      const {id} = useParams();

      useEffect(() => {
        if(id !== 'new'){
            fetch(`/api/v1/lendingstations/${id}`)
            .then(response => response.json())
            .then(data => setLendingstation(data));
        }
      }, [id, setLendingstation]);

      useEffect(() => {
        if (!data.accessToken) {
          navigate('/'); // Redirect to login if not authenticated
        }
      }, [data.accessToken, navigate]);

      const handleChange = (event) => {
        const { name, value } = event.target;
        const updatedLendingStation = { ...lendingstation };
      
        // Handle nested company object
        if (name.startsWith('company.')) {
          const nestedName = name.split('.')[1];
          updatedLendingStation.company[nestedName] = value;
        } else {
          updatedLendingStation[name] = value;
        }
      
        setLendingstation(updatedLendingStation);
      };
      

      const handleSubmit = async (event) => {
        event.preventDefault();

        // Reset error messages
        setNameError('');
        setStatusError('');

        // Validate name
        if (!lendingstation.name.trim()) {
          setNameError('Name cannot be empty');
          return;
        }

        // Validate status
        if (!lendingstation.status.trim()) {
          setStatusError('Status cannot be empty');
          return;
        }

        try {
            const response = await fetch(`/api/v1/lendingstations${lendingstation.id ? `/${lendingstation.id}` : ''}`, {
            method: (lendingstation.id) ? 'PUT' : 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(lendingstation)
            });
            
            setLendingstation(initialFormState);
            navigate('/lendingstations');

            if (response.ok) {
            // Handle the successful response, e.g., show a success message
            console.log('Lending Station created successfully!');
            } else {
            // Handle the error response, e.g., show an error message
            console.error('Error creating Lending Station else block:', response.statusText);
            }
        } catch (error) {
            // Handle any network or other errors
            console.error('Error creating Lending Station catch block:', error);
        }
      }

      const title = <h2>{lendingstation.id ? 'Edit Lending Station' : 'Add Lending Station'}</h2>;
    
      return (<div>
          <AppNavbar/>
          <Container>
            {title}
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label for="name">Name</Label>
                <Input type="text" name="name" id="name" value={lendingstation.name || ''}
                       onChange={handleChange} autoComplete="name"/>
                <div className="text-danger">{nameError}</div>
              </FormGroup>
              <FormGroup>
                <Label for="status">Status</Label>
                <Input type="text" name="status" id="status" value={lendingstation.status || ''}
                       onChange={handleChange} autoComplete="contact-level1"/>
                <div className="text-danger">{statusError}</div>
              </FormGroup>
              <FormGroup>
                <Label for="companyid">companyid</Label>
                <Input
                  type="text"
                  name="company.id" // Update the name attribute to "company.id"
                  id="companyid"
                  value={lendingstation.company?.id || ''} // Ensure that the input field is initialized with the id value of the company object, if it exists.
                  onChange={handleChange}
                  autoComplete="contact-level1"
                />
              </FormGroup>

              <FormGroup>
                <Button color="primary" type="submit">Save</Button>{' '}
                <Button color="secondary" tag={Link} to="/lendingstations">Cancel</Button>
              </FormGroup>
            </Form>
          </Container>
        </div>
      )
};

export default LendingStationEdit;