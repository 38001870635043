import React, {useEffect, useState, useContext} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {Button, Container, Form, FormGroup, Input, Label} from 'reactstrap';
import AppNavbar from '../components/AppNavbar';
import User from '../context/ContextApi';

const StationCoordinatorEdit = () => {
  const { data } = useContext(User);
  const navigate = useNavigate();

      const initialFormState = {
        idNumber: '',
        name: '',
        lendingStation: {
            id: '', 
            name: '', 
            company: {id: '', name: '', status:''}, 
            status:''},
        company: {id: '', name: '', status:''},
        status: '',
        registrationDate: '',
        deregistrationDate: '',
        email: '',
        imei: ''
      };

      const [nameError, setNameError] = useState('');
      const [emailError, setEmailError] = useState('');
      const [imeiError, setImeiError] = useState('');
      const [statusError, setStatusError] = useState('');

      const [stationcoordinator, setStationcoordinator] = useState(initialFormState);
      const {id} = useParams();

      useEffect(() => {
        if(id !== 'new'){
            fetch(`/api/v1/stationcoordinators/${id}`)
            .then(response => response.json())
            .then(data => setStationcoordinator(data));
        }
      }, [id, setStationcoordinator]);

      useEffect(() => {
        if (!data.accessToken) {
          navigate('/'); // Redirect to login if not authenticated
        }
      }, [data.accessToken, navigate]);

      const handleChange = (event) => {
        const { name, value } = event.target;
        const updatedStationCoordinator = { ...stationcoordinator };
      
        // Handle nested company & lendingStation objects
        if (name.startsWith('lendingStation.')) {
            const nestedName = name.split('.')[1];
            // Handle nested company within lendingStation object
            if (nestedName.startsWith('lendingStation.company.')) {
                const nestednestedName = nestedName.split('.')[1];
                updatedStationCoordinator.lendingStation.company[nestednestedName] = value;
            } else {
                updatedStationCoordinator.lendingStation[nestedName] = value;
            }            
        } else if (name.startsWith('company.')) {
            const nestedName = name.split('.')[1];
            updatedStationCoordinator.company[nestedName] = value;
        } else {
            updatedStationCoordinator[name] = value;
        }

        if(id === 'new'){
          const currentDate = new Date();
          const offset = currentDate.getTimezoneOffset() * 60000; // Get the offset in milliseconds

          // Adjust the current date and time by adding the offset for GMT+3
          const adjustedDate = new Date(currentDate.getTime() - offset);

          const formattedDate = adjustedDate.toISOString();
          updatedStationCoordinator.registrationDate = formattedDate;
        }

        setStationcoordinator(updatedStationCoordinator);
      };
      

      const handleSubmit = async (event) => {
        event.preventDefault();

        // Reset error messages
        setNameError('');
        setEmailError('');
        setImeiError('');
        setStatusError('');

        // Validate name
        if (!stationcoordinator.name.trim()) {
          setNameError('Name cannot be empty');
          return;
        }

        // Validate email
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (!emailPattern.test(stationcoordinator.email)) {
          setEmailError('Invalid email');
          return;
        }
      
        // Validate IMEI
        const imeiPattern = /^\d{15}$/;
        if (!imeiPattern.test(stationcoordinator.imei)) {
          setImeiError('Invalid IMEI. It should be 15 numeric characters long.');
          return;
        }

        // Validate status
        if (!stationcoordinator.status.trim()) {
          setStatusError('Status cannot be empty');
          return;
        }

        try {
            const response = await fetch(`/api/v1/stationcoordinators${stationcoordinator.id ? `/${stationcoordinator.id}` : ''}`, {
            method: (stationcoordinator.id) ? 'PUT' : 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(stationcoordinator)
            });
            
            setStationcoordinator(initialFormState);
            navigate('/stationcoordinators');

            if (response.ok) {
            // Handle the successful response, e.g., show a success message
            console.log('Station Coordinator created successfully!');
            } else {
            // Handle the error response, e.g., show an error message
            console.error('Error creating Station Coordinator else block:', response.statusText);
            }
        } catch (error) {
            // Handle any network or other errors
            console.error('Error creating Station Coordinator catch block:', error);
        }
      }

      const title = <h2>{stationcoordinator.id ? 'Edit Station Coordinator' : 'Add Station Coordinator'}</h2>;
    
      return (<div>
          <AppNavbar/>
          <Container>
            {title}
            <Form onSubmit={handleSubmit}>
            <FormGroup>
                <Label for="idNumber">ID Number</Label>
                <Input type="text" name="idNumber" id="idNumber" value={stationcoordinator.idNumber || ''}
                       onChange={handleChange} autoComplete="idNumber"/>
              </FormGroup>
              <FormGroup>
                <Label for="name">Name</Label>
                <Input type="text" name="name" id="name" value={stationcoordinator.name || ''}
                       onChange={handleChange} autoComplete="name"/>
                <div className="text-danger">{nameError}</div>
              </FormGroup>
              <FormGroup>
                <Label for="name">Email</Label>
                <Input type="text" name="email" id="email" value={stationcoordinator.email || ''}
                       onChange={handleChange} autoComplete="email"/>
                <div className="text-danger">{emailError}</div>
              </FormGroup>
              <FormGroup>
                <Label for="name">Imei</Label>
                <Input type="text" name="imei" id="imei" value={stationcoordinator.imei || ''}
                       onChange={handleChange} autoComplete="imei"/>
                <div className="text-danger">{imeiError}</div>
              </FormGroup>
              <FormGroup>
                <Label for="lendingstationid">Lending Station Id</Label>
                <Input
                  type="text"
                  name="lendingStation.id" // Update the name attribute to "lendingStation.id"
                  id="lendingStationid"
                  value={stationcoordinator.lendingStation?.id || ''} // Ensure that the input field is initialized with the id value of the lendingStation object, if it exists.
                  onChange={handleChange}
                  autoComplete="contact-level1"
                />
              </FormGroup>
              <FormGroup>
                <Label for="companyid">company Id</Label>
                <Input
                  type="text"
                  name="company.id" // Update the name attribute to "company.id"
                  id="companyid"
                  value={stationcoordinator.company?.id || ''} // Ensure that the input field is initialized with the id value of the company object, if it exists.
                  onChange={handleChange}
                  autoComplete="contact-level1"
                />
              </FormGroup>
              <FormGroup>
                <Label for="status">Status</Label>
                <Input type="text" name="status" id="status" value={stationcoordinator.status || ''}
                       onChange={handleChange} autoComplete="contact-level1"/>
                <div className="text-danger">{statusError}</div>
              </FormGroup>              

              <FormGroup>
                <Button color="primary" type="submit">Save</Button>{' '}
                <Button color="secondary" tag={Link} to="/stationcoordinators">Cancel</Button>
              </FormGroup>
            </Form>
          </Container>
        </div>
      )
};

export default StationCoordinatorEdit;