import React from 'react';
import '../App.css';
import './Home.css';
import AppNavbar from '../components/AppNavbar';
import { Link } from 'react-router-dom';
import { Card, CardBody, Container } from 'reactstrap';

const Home = ({ isAuthenticated }) => {
    // const [isAuthenticated, setIsAuthenticated] = useState(false);
    // const navigate = useNavigate();
  
    // Check if the user is authenticated
    if (!isAuthenticated) {
        return null; // Return null instead of redirecting
    }
  
    // Render the landing page if the user is authenticated
    return (
        <div>
          <AppNavbar />
          <Container fluid className="text-center card-container">
            <Card>
              <CardBody>
                <Link to="/companies">Companies</Link>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <Link to="/interestsettings">Interest Settings</Link>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <Link to="/lendingstations">Lending Stations</Link>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <Link to="/stationcoordinators">Station Coordinators</Link>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <Link to="/loanissuances">Loans Issued</Link>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <Link to="/loanrepayments">Loan Repayments</Link>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <Link to="/loandefaulters">Loan Defaulters</Link>
              </CardBody>
            </Card>
          </Container>
        </div>
      );
}

export default Home;