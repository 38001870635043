import React, {useEffect, useState, useContext} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {Button, Container, Form, FormGroup, Input, Label} from 'reactstrap';
import AppNavbar from '../components/AppNavbar';
import User from '../context/ContextApi';

const LoanRepaymentEdit = () => {
  const { data } = useContext(User);
  const navigate = useNavigate();

      const initialFormState = {
        idNumber: '',
        loanRepaymentAmount: '',
        loanRepaymentDate: '',
        loanIssuance: {
            id: '',
            idNumber: '',
            issuanceDate: '',
            loanAmount: '',
            loanInterestAmount: '',
            loanInterestPaymentStatus: '',
            loanBalance: '',
            loanDisbursedAmount: '',
            loaneeImage: '',
            name: '',
            phoneNumber: '',
            repaymentDate: '',
            residence: '',
            company: {id: '', name: '', status:''},
            lendingStation: {
                id: '', 
                name: '', 
                company: {id: '', name: '', status:''}, 
                status:''},
            stationCoordinator: {
                id: '',
                idNumber: '',
                name: '',
                lendingStation: {
                    id: '',
                    name: '',
                    company: {id: '', name: '', status:''},
                    status:''
                },
                company: {id: '', name: '', status:''},
                status: '',
                registrationDate: '',
                deregistrationDate: ''
            }
          }
      };

      const [loanrepayment, setLoanrepayment] = useState(initialFormState);
      const {id} = useParams();

      useEffect(() => {
        if(id !== 'new'){
            fetch(`/api/v1/loanrepayments/${id}`)
            .then(response => response.json())
            .then(data => setLoanrepayment(data));
        }
      }, [id, setLoanrepayment]);

      useEffect(() => {
        if (!data.accessToken) {
          navigate('/'); // Redirect to login if not authenticated
        }
      }, [data.accessToken, navigate]);

      const handleChange = (event) => {
        const { name, value } = event.target;
        const updatedLoanRepayment = { ...loanrepayment };
      
        // Handle nested company object
        if (name.startsWith('company.')) {
          const nestedName = name.split('.')[1];
          updatedLoanRepayment.company[nestedName] = value;
        } else {
          updatedLoanRepayment[name] = value;
        }
      
        setLoanrepayment(updatedLoanRepayment);
      };
      

      const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await fetch(`/api/v1/loanrepayments${loanrepayment.id ? `/${loanrepayment.id}` : ''}`, {
            method: (loanrepayment.id) ? 'PUT' : 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(loanrepayment)
            });
            
            setLoanrepayment(initialFormState);
            navigate('/loanrepayments');

            if (response.ok) {
            // Handle the successful response, e.g., show a success message
            console.log('Loan Repayment created successfully!');
            } else {
            // Handle the error response, e.g., show an error message
            console.error('Error creating Loan Repayment else block:', response.statusText);
            }
        } catch (error) {
            // Handle any network or other errors
            console.error('Error creating Loan Repayment catch block:', error);
        }
      }

      const title = <h2>{loanrepayment.id ? 'Edit Loan Repayment' : 'Add Loan Repayment'}</h2>;
    
      return (<div>
          <AppNavbar/>
          <Container>
            {title}
            <Form onSubmit={handleSubmit}>
            <FormGroup>
                <Label for="idNumber">ID Number</Label>
                <Input type="text" name="idNumber" id="idNumber" value={loanrepayment.idNumber || ''}
                       onChange={handleChange} autoComplete="idNumber"/>
              </FormGroup>
              <FormGroup>
                <Label for="loanRepaymentAmount">Loan Repayment Amount</Label>
                <Input type="text" name="loanRepaymentAmount" id="loanRepaymentAmount" value={loanrepayment.loanRepaymentAmount || ''}
                       onChange={handleChange} autoComplete="amount"/>
              </FormGroup>
              <FormGroup>
                <Label for="loanRepaymentDate">Loan Repayment Date</Label>
                <Input type="text" name="loanRepaymentDate" id="loanRepaymentDate" value={loanrepayment.loanRepaymentDate || ''}
                       onChange={handleChange} autoComplete="date"/>
              </FormGroup>
              <FormGroup>
                <Label for="loanissuanceid">Loan Issuance Id</Label>
                <Input
                  type="text"
                  name="loanIssuance.id" // Update the name attribute to "loanIssuance.id"
                  id="loanIssuanceid"
                  value={loanrepayment.loanIssuance?.id || ''} // Ensure that the input field is initialized with the id value of the loanIssuance object, if it exists.
                  onChange={handleChange}
                  autoComplete="contact-level1"
                />
              </FormGroup>

              <FormGroup>
                <Button color="primary" type="submit">Save</Button>{' '}
                <Button color="secondary" tag={Link} to="/loanrepayments">Cancel</Button>
              </FormGroup>
            </Form>
          </Container>
        </div>
      )
};

export default LoanRepaymentEdit;