import React, {useEffect, useState, useRef, useContext} from 'react';
import {Button, ButtonGroup, Container, Table, Pagination, PaginationItem, PaginationLink} from 'reactstrap';
import AppNavbar from '../components/AppNavbar';
import {Link, useNavigate } from 'react-router-dom';
import User from '../context/ContextApi';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';

const LoanDefaultersList = () => {
  const { data } = useContext(User);
  const navigate = useNavigate();

  const defaultNumber = -1;
  const emptyString = '';
  const defaultDate = '1900-01-01';
  const firstPage = 1;

  const [loanDefaulters, setLoanDefaulters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(firstPage);
  const [pageSize] = useState(5);
  const [totalCount, setTotalCount] = useState(0);
  const prevPage = useRef(firstPage);
  // New state variables for the search terms
  const [idFilter, setIdFilter] = useState(defaultNumber);
  const [nameFilter, setNameFilter] = useState(emptyString);
  const [idNumberFilter, setIdNumberFilter] = useState(defaultNumber);
  const [phoneNumberFilter, setPhoneNumberFilter] = useState(defaultNumber);
  const [loanAmountFilter, setLoanAmountFilter] = useState(defaultNumber);
  const [loanBalanceFilter, setLoanBalanceFilter] = useState(defaultNumber);
  const [issuanceDateFilter, setIssuanceDateFilter] = useState(defaultDate);
  const [repaymentDateFilter, setRepaymentDateFilter] = useState(defaultDate);
  const [daysDefaultedFilter, setDaysDefaultedFilter] = useState(defaultNumber);
  const [penaltyPercentageFilter, setPenaltyPercentageFilter] = useState(defaultNumber);
  const [pendingPenaltyFilter, setPendingPenaltyFilter] = useState(defaultNumber);
  const [balanceFilter, setBalanceFilter] = useState(defaultNumber);
  const [statusFilter, setStatusFilter] = useState(emptyString);
  const [clearanceDateFilter, setClearanceDateFilter] = useState(defaultDate);
  const [loanIssuanceIdFilter, setLoanIssuanceIdFilter] = useState(defaultNumber);

  // State variable to store the timer reference
  const searchTimer = useRef(null);

  const debouncedSearchTimeoutInMs = 1000;
  
  useEffect(() => {
    // Clear the previous timer before setting a new one
    if (searchTimer.current) {
      clearTimeout(searchTimer.current);
    }

    // Set a new timer to call the fetchData function after a delay
    searchTimer.current = setTimeout(() => {
      setLoading(true);

      const pageNo = currentPage  - 1;

      const idPlacer = idFilter === defaultNumber ? emptyString : `&id=${encodeURIComponent(idFilter)}`;
      const namePlacer = nameFilter === emptyString ? emptyString : `&name=${encodeURIComponent(nameFilter)}`;
      const idNumberPlacer = idNumberFilter === defaultNumber ? emptyString : `&idNumber=${encodeURIComponent(idNumberFilter)}`;
      const phoneNumberPlacer = phoneNumberFilter === defaultNumber ? emptyString : `&phoneNumber=${encodeURIComponent(phoneNumberFilter)}`;
      const loanAmountPlacer = loanAmountFilter === defaultNumber ? emptyString : `&loanAmount=${encodeURIComponent(loanAmountFilter)}`;
      const loanBalancePlacer = loanBalanceFilter === defaultNumber ? emptyString : `&loanBalance=${encodeURIComponent(loanBalanceFilter)}`;
      const issuanceDatePlacer = issuanceDateFilter === defaultDate ? emptyString : `&issuanceDate=${encodeURIComponent(issuanceDateFilter)}`;
      const repaymentDatePlacer = repaymentDateFilter === defaultDate ? emptyString : `&repaymentDate=${encodeURIComponent(repaymentDateFilter)}`;
      const daysDefaultedPlacer = daysDefaultedFilter === defaultNumber ? emptyString : `&daysDefaulted=${encodeURIComponent(daysDefaultedFilter)}`;
      const penaltyPercentagePlacer = penaltyPercentageFilter === defaultNumber ? emptyString : `&penaltyPercentage=${encodeURIComponent(penaltyPercentageFilter)}`;
      const pendingPenaltyPlacer = pendingPenaltyFilter === defaultNumber ? emptyString : `&pendingPenalty=${encodeURIComponent(pendingPenaltyFilter)}`;
      const balancePlacer = balanceFilter === defaultNumber ? emptyString : `&balance=${encodeURIComponent(balanceFilter)}`;
      const statusPlacer = statusFilter === emptyString ? emptyString : `&status=${encodeURIComponent(statusFilter)}`;
      const clearanceDatePlacer = clearanceDateFilter === defaultDate ? emptyString : `&clearanceDate=${encodeURIComponent(clearanceDateFilter)}`;
      const loanIssuanceIdPlacer = loanIssuanceIdFilter === defaultNumber ? emptyString : `&loanIssuanceId=${encodeURIComponent(loanIssuanceIdFilter)}`;

      const url = `api/v1/loandefaulters?pageNo=${pageNo}&pageSize=${pageSize}${idPlacer}${namePlacer}${idNumberPlacer}${phoneNumberPlacer}${loanAmountPlacer}${loanBalancePlacer}${issuanceDatePlacer}${repaymentDatePlacer}${daysDefaultedPlacer}${penaltyPercentagePlacer}${pendingPenaltyPlacer}${balancePlacer}${statusPlacer}${clearanceDatePlacer}${loanIssuanceIdPlacer}`;

      // Clear the loanDefaulters state if navigating to a non-sequential page
      if (currentPage !== prevPage.current) {
        setLoanDefaulters([]);
      }

      // Perform the API call and update state
      fetch(url)
        .then(response => response.json())
        .then(data => {
          setLoanDefaulters(prevLoanDefaulters => (currentPage === firstPage ? data.loanDefaulters : [...prevLoanDefaulters, ...data.loanDefaulters]));
          setTotalCount(data.totalCount);
          setLoading(false);
        });

      prevPage.current = currentPage; // Update the previous page
    }, debouncedSearchTimeoutInMs); // Adjust the time (in milliseconds) based on your preference

    // Clear the timer when the component unmounts or when the search term changes
    return () => clearTimeout(searchTimer.current);
  }, [defaultNumber, currentPage, pageSize, idFilter, nameFilter, idNumberFilter, phoneNumberFilter, loanAmountFilter, loanBalanceFilter, issuanceDateFilter, repaymentDateFilter, daysDefaultedFilter, penaltyPercentageFilter, pendingPenaltyFilter, balanceFilter, statusFilter, clearanceDateFilter, loanIssuanceIdFilter]);

  useEffect(() => {
    if (!data.accessToken) {
      navigate('/'); // Redirect to login if not authenticated
    }
  }, [data.accessToken, navigate]);

  // Handle the input changes and set the search terms
  const handleIdChange = (e) => {
    setIdFilter((!e.target.value || e.target.value === '-') ? defaultNumber : e.target.value);
    setCurrentPage(firstPage);
  };

  const handleNameChange = (e) => {
    setNameFilter(e.target.value);
    setCurrentPage(firstPage);
  };
  
  const handleIdNumberChange = (e) => {
    setIdNumberFilter((!e.target.value || e.target.value === '-') ? defaultNumber : e.target.value);
    setCurrentPage(firstPage);
  };
  
  const handlePhoneNumberChange = (e) => {
    setPhoneNumberFilter((!e.target.value || e.target.value === '-') ? defaultNumber : e.target.value);
    setCurrentPage(firstPage);
  };
  
  const handleLoanAmountChange = (e) => {
    setLoanAmountFilter((!e.target.value || e.target.value === '-') ? defaultNumber : e.target.value);
    setCurrentPage(firstPage);
  };
  
  const handleLoanBalanceChange = (e) => {
    setLoanBalanceFilter((!e.target.value || e.target.value === '-') ? defaultNumber : e.target.value);
    setCurrentPage(firstPage);
  };

  const handleIssuanceDateChange = (e) => {
    setIssuanceDateFilter(!e.target.value ? defaultDate : e.target.value);
    setCurrentPage(firstPage);
  };

  const handleRepaymentDateChange = (e) => {
    setRepaymentDateFilter(!e.target.value ? defaultDate : e.target.value);
    setCurrentPage(firstPage);
  };

  const handleDaysDefaultedChange = (e) => {
    setDaysDefaultedFilter((!e.target.value || e.target.value === '-') ? defaultNumber : e.target.value);
    setCurrentPage(firstPage);
  };

  const handlePenaltyPercentageChange = (e) => {
    setPenaltyPercentageFilter((!e.target.value || e.target.value === '-') ? defaultNumber : e.target.value);
    setCurrentPage(firstPage);
  };

  const handlePendingPenaltyChange = (e) => {
    setPendingPenaltyFilter((!e.target.value || e.target.value === '-') ? defaultNumber : e.target.value);
    setCurrentPage(firstPage);
  };
  
  const handleBalanceChange = (e) => {
    setBalanceFilter((!e.target.value || e.target.value === '-') ? defaultNumber : e.target.value);
    setCurrentPage(firstPage);
  };
  
  const handleStatusChange = (e) => {
    setStatusFilter(e.target.value);
    setCurrentPage(firstPage);
  };
  
  const handleClearanceDateChange = (e) => {
    setClearanceDateFilter(!e.target.value ? defaultDate : e.target.value);
    setCurrentPage(firstPage);
  };
  
  const handleLoanIssuanceIdChange = (e) => {
    setLoanIssuanceIdFilter((!e.target.value || e.target.value === '-') ? defaultNumber : e.target.value);
    setCurrentPage(firstPage);
  };

  const remove = async (id) => {
    await fetch(`api/v1/loandefaulters/${id}`, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(() => {
      let updatedLoanDefaulters = [...loanDefaulters].filter(i => i.id !== id);
      setLoanDefaulters(updatedLoanDefaulters);
    })
  }

  if(loading){
    return <p>Loading...</p>;
  }

  // Calculate indexes of the loanDefaulters to be displayed on the current page
  const indexOfLastLoanDefaulters = Math.min(currentPage * pageSize, loanDefaulters.length);
  const indexOfFirstLoanDefaulters = Math.max(indexOfLastLoanDefaulters - pageSize, 0);
  const currentLoanDefaulters = loanDefaulters.slice(indexOfFirstLoanDefaulters, indexOfLastLoanDefaulters);

  const loanDefaultersList = currentLoanDefaulters.map(loanDefaulters => {
    if (!loanDefaulters) {
        return null; // Skip rendering if the loanDefaulters is undefined or null
    }

    return <tr key={loanDefaulters.id}>
        <td style={{whiteSpace: 'nowrap'}}>{loanDefaulters.id}</td>
        <td style={{whiteSpace: 'nowrap'}}>{loanDefaulters.name}</td>
        <td style={{whiteSpace: 'nowrap'}}>{loanDefaulters.idNumber}</td>
        <td style={{whiteSpace: 'nowrap'}}>{loanDefaulters.phoneNumber}</td>
        <td style={{whiteSpace: 'nowrap'}}>{loanDefaulters.loanAmount}</td>
        <td style={{whiteSpace: 'nowrap'}}>{loanDefaulters.loanBalance}</td>
        <td style={{whiteSpace: 'nowrap'}}>{loanDefaulters.issuanceDate}</td>
        <td style={{whiteSpace: 'nowrap'}}>{loanDefaulters.repaymentDate}</td>
        <td style={{whiteSpace: 'nowrap'}}>{loanDefaulters.daysDefaulted}</td>
        <td style={{whiteSpace: 'nowrap'}}>{loanDefaulters.penaltyPercentage}</td>
        <td style={{whiteSpace: 'nowrap'}}>{loanDefaulters.pendingPenalty}</td>
        <td style={{whiteSpace: 'nowrap'}}>{loanDefaulters.balance}</td>
        <td style={{whiteSpace: 'nowrap'}}>{loanDefaulters.status}</td>
        <td style={{whiteSpace: 'nowrap'}}>{loanDefaulters.clearanceDate}</td>
        <td style={{whiteSpace: 'nowrap'}}>
            {loanDefaulters.loanIssuance ? loanDefaulters.loanIssuance.id : emptyString}
        </td>
        <td>
            <ButtonGroup>
                <Button size="sm" color="primary" tag={Link} to={"/loandefaulters/"+loanDefaulters.id} disabled>Edit</Button>
                <Button size="sm" color="danger" onClick={() => remove(loanDefaulters.id)} disabled>Delete</Button>
            </ButtonGroup>
        </td>
    </tr>
  }); 

  // Change page
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Calculate total number of pages
  const totalPages = Math.ceil(totalCount / pageSize);

  library.add(faAngleDoubleLeft, faAngleDoubleRight);

  const renderPaginationItems = () => {
    const visiblePageCount = 5;
    const ellipsis = <span>...</span>;
  
    if (totalPages <= visiblePageCount) {
      return Array.from({ length: totalPages }, (_, i) => (
        <PaginationItem key={i} active={i + 1 === currentPage}>
          <PaginationLink onClick={() => handlePageChange(i + 1)}>{i + 1}</PaginationLink>
        </PaginationItem>
      ));
    }
  
    const isStartEllipsisNeeded = currentPage > visiblePageCount - Math.floor(visiblePageCount / 2);
    const isEndEllipsisNeeded = currentPage < totalPages - Math.floor(visiblePageCount / 2);
  
    const paginationItems = [];
    let startPage = firstPage;
    let endPage = totalPages;
  
    if (isStartEllipsisNeeded) {
      startPage = currentPage - Math.floor(visiblePageCount / 2);
      paginationItems.push(
        <PaginationItem key="start-ellipsis" disabled>
          {ellipsis}
        </PaginationItem>
      );
    }
  
    if (isEndEllipsisNeeded) {
      endPage = currentPage + Math.floor(visiblePageCount / 2);
    }
  
    for (let i = startPage; i <= endPage; i++) {
      paginationItems.push(
        <PaginationItem key={i} active={i === currentPage}>
          <PaginationLink onClick={() => handlePageChange(i)}>{i}</PaginationLink>
        </PaginationItem>
      );
    }
  
    if (isEndEllipsisNeeded) {
      paginationItems.push(
        <PaginationItem key="end-ellipsis" disabled>
          {ellipsis}
        </PaginationItem>
      );
    }
  
    return paginationItems;
  };  

  return (
    <div>
        <AppNavbar/>
        <Container fluid>
            <div className="float-end">
                <Button color="success" tag={Link} to="/loandefaulters/new" disabled>Add Loan Defaulters</Button>
            </div>
            <h3>Loan Defaulters</h3>
            <Table className="mt-4" responsive>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th width="10%">Name</th>
                        <th>ID Number</th>
                        <th width="5%">Phone Number</th>
                        <th>Loan Amount</th>
                        <th>Loan Balance</th>
                        <th>Issuance Date</th>
                        <th>Maturity Date</th>
                        <th>Days Defaulted</th>
                        <th>Penalty Percentage</th>
                        <th>Pending Penalty</th>
                        <th>Balance</th>
                        <th>Status</th>
                        <th>Clearance Date</th>
                        <th>Loan Issuance Id</th>
                        <th width="5%">Actions</th>
                    </tr>
                    <tr>
                        <td>
                          <input
                          type="text"
                          id="id"
                          value={idFilter}
                          onChange={handleIdChange}
                          style={{ width: '100px' }} />
                        </td>
                        <td>
                          <input
                          type="text"
                          id="name" 
                          value={nameFilter}
                          onChange={handleNameChange}
                          style={{ width: '200px' }} />
                        </td>
                        <td>
                          <input
                          type="text"
                          id="idNumber"
                          value={idNumberFilter}
                          onChange={handleIdNumberChange}
                          style={{ width: '100px' }} />
                        </td>
                        <td>
                          <input
                          type="text"
                          id="phoneNumber"
                          value={phoneNumberFilter}
                          onChange={handlePhoneNumberChange}
                          style={{ width: '100px' }} />
                          </td>
                        <td>
                          <input
                          type="text"
                          id="loanAmount"
                          value={loanAmountFilter}
                          onChange={handleLoanAmountChange}
                          style={{ width: '70px' }} />
                          </td>
                        <td>
                          <input
                          type="text"
                          id="loanBalance"
                          value={loanBalanceFilter}
                          onChange={handleLoanBalanceChange}
                          style={{ width: '70px' }} />
                        </td>
                        <td>
                          <input
                          type="text"
                          id="issuanceDate"
                          value={issuanceDateFilter}
                          onChange={handleIssuanceDateChange}
                          style={{ width: '100px' }} />
                        </td>
                        <td>
                          <input
                          type="text"
                          id="repaymentDate"
                          value={repaymentDateFilter}
                          onChange={handleRepaymentDateChange}
                          style={{ width: '100px' }} />
                        </td>
                        <td>
                          <input
                          type="text"
                          id="daysDefaulted" 
                          value={daysDefaultedFilter}
                          onChange={handleDaysDefaultedChange}
                          style={{ width: '70px' }} />
                        </td>
                        <td>
                          <input
                          type="text"
                          id="penaltyPercentage" 
                          value={penaltyPercentageFilter}
                          onChange={handlePenaltyPercentageChange}
                          style={{ width: '70px' }} />
                        </td>
                        <td>
                          <input
                          type="text"
                          id="pendingPenalty" 
                          value={pendingPenaltyFilter}
                          onChange={handlePendingPenaltyChange}
                          style={{ width: '70px' }} />
                        </td>
                        <td>
                          <input
                          type="text"
                          id="balance"
                          value={balanceFilter}
                          onChange={handleBalanceChange}
                          style={{ width: '70px' }} />
                        </td>
                        <td>
                          <input
                          type="text"
                          id="status"
                          value={statusFilter}
                          onChange={handleStatusChange}
                          style={{ width: '80px' }} />
                        </td>
                        <td>
                          <input
                          type="text"
                          id="clearanceDate"
                          value={clearanceDateFilter}
                          onChange={handleClearanceDateChange}
                          style={{ width: '100px' }} />
                        </td>
                        <td>
                          <input
                          type="text"
                          id="loanIssuanceId"
                          value={loanIssuanceIdFilter}
                          onChange={handleLoanIssuanceIdChange}
                          style={{ width: '100px' }} />
                        </td>
                        <td></td>
                    </tr>
                </thead>
                <tbody>
                    {loanDefaultersList}
                </tbody>
            </Table>
            {totalPages > 1 && (
            <Pagination>
              <PaginationItem disabled={currentPage === firstPage}>
                <PaginationLink onClick={() => handlePageChange(firstPage)}>
                  <FontAwesomeIcon icon="angle-double-left" />
                </PaginationLink>
              </PaginationItem>
              <PaginationItem disabled={currentPage === firstPage}>
                <PaginationLink onClick={() => handlePageChange(currentPage - 1)}>
                  Prev
                </PaginationLink>
              </PaginationItem>
              {renderPaginationItems()}
              <PaginationItem disabled={currentPage === totalPages}>
                <PaginationLink onClick={() => handlePageChange(currentPage + 1)}>
                  Next
                </PaginationLink>
              </PaginationItem>
              <PaginationItem disabled={currentPage === totalPages}>
                <PaginationLink onClick={() => handlePageChange(totalPages)}>
                  <FontAwesomeIcon icon="angle-double-right" />
                </PaginationLink>
              </PaginationItem>
            </Pagination>
            )}
        </Container>
    </div>
  );
}

export default LoanDefaultersList;